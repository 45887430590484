import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Navbar from "../../components/navbar";
import "./style.css";
import "../../embla.css";
import { useLocation, useNavigate } from "react-router-dom";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { CreateEventForm } from "../../components/create-event-form";
import { EventForm, EventSearchData } from "../landing-page/types";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import axios from "axios";
import {
  COLORS,
  PROD_URL,
} from "../../helpers/constants";
import { AddInfo, Category, CategoryValues } from "./types";
import AddInfoModal from "../../components/add-info-modal";
import moment, { Moment } from "moment";
import Slider from "react-slick";
import { IoMdAdd } from "react-icons/io";
import {
  checkTokenExpiry,
  compareServiceArrays,
  convertToKebabCase,
  getCreateEventAmount,
  isVideo,
  titleCase,
} from "../../helpers/utils";
import ReactOwlCarousel from "react-owl-carousel";
import useEmblaCarousel from "embla-carousel-react";
import { IoArrowForward } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { supplierOptions } from "./data";
import { servicesWithIcon } from "../../components/create-event-form/data";
import Footer from "../../components/footer";
import { Service } from "../my-events/types";
import { AuthContext } from "../../context/AuthContext";
import { ControlBar, Player } from "video-react";

export default function CreateEvent() {
  const authContext = useContext(AuthContext);
  const location = useLocation();
  const {
    searchData = {
      date: moment().format("MMM DD, YYYY"),
      city: "Select",
      budget: "",
      noOfGuests: "",
      timeFrom: "From",
      timeTill: "To",
      services: ["Select"],
      eventType: "Select",
    },
    searchResponse = {},
  }: { searchData: EventSearchData; searchResponse: any } =
    location.state || {};

  const [searchedData, setSearchedData] = useState<EventSearchData>(
    JSON.parse(sessionStorage.getItem("searchedData") ?? "null") || searchData
  );

  const [eventFormData, setEventFormData] = useState<EventForm>({
    date: moment(searchedData.date || searchData.date),
    city: searchedData.city || searchData.city || "Select",
    budget: searchedData.budget || searchData.budget || "",
    timeStart: searchedData.timeFrom || searchData.timeFrom || "From",
    timeEnd: searchedData.timeTill || searchData.timeTill || "To",
    services: searchedData.services || searchData.services,
    eventType: searchedData.eventType || searchData.eventType || "Select",
    guests: searchedData.noOfGuests || searchData.noOfGuests || "",
  });

  const [addInfoData, setAddInfoData] = useState<AddInfo>({
    maxBudget: "",
    timeStart: "Select",
    timeEnd: "Select",
    detailedInfo: "",
  });
  const [showAddInfoModal, setShowAddInfoModal] = useState(false);

  // const [timeStart, setTimeStart] = useState<string>(
  //   addInfo?.timeStart || "Select"
  // );
  // const [timeEnd, setTimeEnd] = useState<string>(addInfo?.timeEnd || "Select");
  // const [maxBudget, setMaxBudget] = useState(addInfo?.maxBudget || "");
  // const [detailedInfo, setDetailedInfo] = useState(addInfo?.detailedInfo || "");

  const [serviceData, setServiceData] = useState<any[]>(
    JSON.parse(sessionStorage.getItem("serviceData") ?? "null") ||
      searchResponse.services ||
      []
  );
  const [noBrainerData, setNoBrainerData] = useState<any[]>(
    JSON.parse(sessionStorage.getItem("noBrainerData") ?? "null") ||
      searchResponse.noBrainerDeals ||
      []
  );
  const [timeline, setTimeline] = useState(false);

  const [activeCategory, setActiveCategory] = useState(
    sessionStorage.getItem("activeCategory") ||
      searchResponse.services?.[0]?.category ||
      searchResponse.noBrainerDeals?.[0]?.category ||
      ""
  );
  const [chatBotOpen, setChatBotOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [currentPath, setCurrentPath] = useState("");
  const [categories, setCategories] = useState<Category>(
    JSON.parse(sessionStorage.getItem("categories") ?? "{}")
  );
  const [totalPrice, setTotalPrice] = useState(0);
  // const [taxPrice, setTaxPrice] = useState(0);
  // const [unitPrice, setUnitPrice] = useState(0);
  // const [unitType, setUnitType] = useState("");
  const [formLoading, setFormLoading] = useState(false);
  const [requestEventLoading, setRequestEventLoading] = useState(false);
  const [infoModalCategory, setInfoModalCategory] = useState("");
  const storedTimelineServices = JSON.parse(
    sessionStorage.getItem("timelineServices") ?? "[]"
  );
  const parsedTimelineServices = storedTimelineServices.map((task: Task) => ({
    ...task,
    start: new Date(task.start),
    end: new Date(task.end),
  }));
  const [timelineServices, setTimelineServices] = useState<Task[]>(
    parsedTimelineServices
  );
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(
    JSON.parse(sessionStorage.getItem("selectedIndex") || "0")
  );
  const navigate = useNavigate();
  const [emblaRef1, emblaApi] = useEmblaCarousel({
    align: "start",
    startIndex: selectedIndex,
  });
  const [emblaRef2] = useEmblaCarousel({ align: "start" });
  const [emblaRef3] = useEmblaCarousel({ align: "start" });
  const [openService, setOpenService] = useState(false);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelectEmbla = useCallback(
    (emblaApi: any) => {
      setPrevBtnDisabled(!emblaApi.canScrollPrev());
      setNextBtnDisabled(!emblaApi.canScrollNext());
      // if (screenWidth <= 900) {
      const selectedIndex = emblaApi.selectedScrollSnap();
      sessionStorage.setItem("selectedIndex", JSON.stringify(selectedIndex));
      console.log("selectedIndex:", selectedIndex);
      console.log("searchData.services:", searchedData.services);
      const key = searchedData.services[selectedIndex];
      updateActiveCard(key);
      // }
    },
    [searchedData]
  );

  useEffect(() => {
    if (!emblaApi) return;

    onSelectEmbla(emblaApi);
    emblaApi.on("reInit", onSelectEmbla);
    emblaApi.on("select", onSelectEmbla);
  }, [emblaApi, onSelectEmbla]);

  // const next = () => {
  //   //@ts-ignore
  //   sliderRef.slickNext();
  // };
  // const previous = () => {
  //   //@ts-ignore
  //   sliderRef.slickPrev();
  // };

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
    setScreenHeight(window.innerHeight);
  };

  useEffect(() => {
    // Add event listener to update screen width on resize
    window.addEventListener("resize", updateScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  async function updateSearch() {
    console.log("update search triggered");
    setFormLoading(true);

    sessionStorage.removeItem("serviceData");
    sessionStorage.removeItem("noBrainerData");

    try {
      const {
        date,
        city,
        budget,
        timeStart,
        timeEnd,
        services,
        eventType,
        guests,
      } = eventFormData;

      if (city == "Select") {
        throw Error("Select city");
      }

      if (timeStart == "From") {
        throw Error("Select start time");
      }

      if (timeEnd == "To") {
        throw Error("Select end time");
      }

      if (eventType == "Select") {
        throw Error("Select event type");
      }

      if (isEmpty(services) || services.includes("Select")) {
        throw Error("Select atleast 1 service");
      }

      if (Number(guests) <= 0) {
        throw Error("Number of guests must be greater than 0");
      }

      const data: EventSearchData = {
        date: date.format("MMM DD, YYYY"),
        city: city == "Select" ? "" : city,
        budget,
        noOfGuests: guests,
        timeFrom: timeStart == "From" ? "" : timeStart,
        timeTill: timeEnd == "To" ? "" : timeEnd,
        services,
        eventType: eventType == "Select" ? "" : eventType,
      };

      console.log(data);

      const response = await axios.post(PROD_URL + "/search", data);
      console.log(response);
      const searchRes = response.data;

      if (isEmpty(searchRes.services) && isEmpty(searchRes.noBrainerDeals)) {
        toast.error("No services were found for this search");
      }

      setSearchedData(data);
      sessionStorage.setItem("searchedData", JSON.stringify(data));

      const selectedCategory =
        searchRes.services?.[0]?.category ||
        searchRes.noBrainerDeals?.[0]?.category ||
        "";

      setActiveCategory(selectedCategory);
      sessionStorage.setItem("activeCategory", selectedCategory);

      console.log("searchRes.services:", searchRes.services);
      console.log("searchRes.noBrainerDeals:", searchRes.noBrainerDeals);

      sessionStorage.setItem("serviceData", JSON.stringify(searchRes.services));
      sessionStorage.setItem(
        "noBrainerData",
        JSON.stringify(searchRes.noBrainerDeals)
      );
      setServiceData(searchRes.services);
      setNoBrainerData(searchRes.noBrainerDeals);
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setFormLoading(false);
    }
  }

  //updating category
  useEffect(() => {
    sessionStorage.setItem("searchedData", JSON.stringify(searchedData));
    sessionStorage.setItem("serviceData", JSON.stringify(serviceData));
    sessionStorage.setItem("noBrainerData", JSON.stringify(noBrainerData));
    console.log("category update running");

    let categoryData: Category = { ...categories };
    const combinedData = [...serviceData, ...noBrainerData];

    const categoriesInCombinedData = new Set(
      combinedData.map((item) => item.category)
    );

    searchedData.services.forEach((item) => {
      if (!categoryData[item]) {
        categoryData[item] = {
          unitPrice: 0,
          totalPrice: 0,
          unitName: "price",
          serviceId: "",
          enableNoBrainer: false,
        };
      }
    });

    // setTimelineServices(
    //   timelineServices.filter((item) => categoriesInCombinedData.has(item.name))
    // );

    let updatedServices = [...timelineServices];

    categoriesInCombinedData.forEach((category) => {
      if (isNewServiceByCategory(category)) {
        // Service is new, add it
        updatedServices.push({
          start: new Date(new Date().setHours(0, 15, 0, 0)),
          end: new Date(new Date().setHours(0, 15, 0, 0)),
          name: category,
          id: "",
          type: "task",
          progress: 100,
          isDisabled: false,
          styles: {
            progressColor: "var(--bg-light-color)",
            progressSelectedColor: "var(--bg-light-color)",
          },
        });
        console.log(`Service with name ${category} added.`);
      }
      // else {
      //   console.log(`Service with name ${category} updated.`);
      // }
    });

    setTimelineServices(updatedServices);

    setTimelineServices(
      updatedServices.filter((item) => categoriesInCombinedData.has(item.name))
    );

    sessionStorage.setItem("timelineServices", JSON.stringify(updatedServices));

    Object.keys(categoryData).forEach((category) => {
      if (!searchedData.services.includes(category)) {
        delete categoryData[category];
      }
    });

    if (sessionStorage.getItem("dataFromSupplierInfo")) {
      const data = JSON.parse(
        sessionStorage.getItem("dataFromSupplierInfo") ?? "{}"
      );
      console.log("data from supplier info:", data);
      const { unitName, total_amount, unit_price } = getCreateEventAmount(
        Number(eventFormData.guests),
        !data.enableNoBrainerDeal
          ? data.priceTypeSelected
          : data.noBrainerPriceTypeSelected,
        data
      );
      let isNoBrainer = false;
      if (sessionStorage.getItem("isNoBrainer")) {
        isNoBrainer = sessionStorage.getItem("isNoBrainer") === "true";
        sessionStorage.removeItem("isNoBrainer");
      }
      categoryData[data.category] = {
        totalPrice: data.totalPrice,
        unitPrice: unit_price,
        unitName: unitName,
        serviceId: data.serviceId,
        background: data.photoAndVideoUrls?.[0] ?? "",
        enableNoBrainer: isNoBrainer,
        serviceData: data,
      };
      // setTotalPrice(totalPrice + data.totalPrice);
      // setPricePerPerson(pricePerPerson + data.pricePerPerson);
      console.log("categoryData for supplier", categoryData);
      // sessionStorage.setItem("categories", JSON.stringify(categoryData));
      sessionStorage.removeItem("dataFromSupplierInfo");
      toast.success(`Selected ${data.category}`);
    }

    let total = 0,
      unitPrice = 0;

    Object.values(categoryData).forEach((item) => {
      total += item.totalPrice;
      unitPrice += item.unitPrice;
    });

    setTotalPrice(total);
    //setUnitPrice(unitPrice);
    setCategories(categoryData);
    sessionStorage.setItem("categories", JSON.stringify(categoryData));
  }, [serviceData, noBrainerData]);

  const addServiceToEvent = (
    data: any,
    event: any,
    noBrainerEnable: boolean
  ) => {
    event.stopPropagation();
    let categoryData: Category = { ...categories };
    const { unitName, total_amount, unit_price } = getCreateEventAmount(
      Number(eventFormData.guests),
      !data.enableNoBrainerDeal
        ? data.priceTypeSelected
        : data.noBrainerPriceTypeSelected,
      data
    );

    categoryData[data.category] = {
      ...categoryData[data.category], // Retain existing properties
      totalPrice: data.totalPrice,
      unitPrice: unit_price,
      unitName: unitName,
      serviceId: data.serviceId,
      background: data.photoAndVideoUrls?.[0] ?? "",
      enableNoBrainer: noBrainerEnable,
      serviceData: data,
    };

    let total = 0,
      unitPrice = 0;

    Object.values(categoryData).forEach((item) => {
      total += item.totalPrice;
      unitPrice += item.unitPrice;
    });

    setTotalPrice(total);
    //setUnitPrice(unitPrice);
    setCategories(categoryData);
    sessionStorage.setItem("categories", JSON.stringify(categoryData));
    toast.success(`Selected ${data.category}`);
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    Object.entries(categories).map(([key, item], index) => {
      const { unitName, total_amount, unit_price } = getCreateEventAmount(
        Number(eventFormData.guests),
        !item.enableNoBrainer
          ? item.serviceData?.priceTypeSelected!
          : item.serviceData?.noBrainerPriceTypeSelected!,
        item.serviceData!,
        item.addInfo?.timeStart,
        item.addInfo?.timeEnd
      );
      totalPrice += total_amount;
    });
    return totalPrice;
  };

  // gantt chart setting
  useEffect(() => {
    const divElement = document.querySelector("._WuQ0f:first-child");
    const services = document.querySelectorAll("._3lLk3:first-child");
    const timeValues = document.querySelectorAll("._9w8d5");
    console.log("services:", services);

    if (divElement) {
      divElement.textContent = "Service Name";
    }

    if (timeValues) {
      timeValues.forEach((timeValue) => {
        if (
          timeValue.textContent?.length &&
          timeValue.textContent?.length <= 2
        ) {
          timeValue.textContent = timeValue.textContent + ":00";
        }
      });
    }
  }, [timelineServices]);

  const updateActiveCard = (category: string) => {
    console.log("update active card");
    console.log("category:", category);
    setActiveCategory(category);
    sessionStorage.setItem("activeCategory", category);
  };

  function getMarginTop(size: number) {
    let margintop;
    if (screenWidth >= 1600 && size < 5) {
      margintop = 0;
    } else if (screenWidth >= 1600 && size >= 5) {
      margintop = -65;
    } else if (screenWidth >= 1200 && size < 4) {
      margintop = 0;
    } else if (screenWidth >= 1200 && size >= 4) {
      margintop = -65;
    } else if (screenWidth >= 850 && size < 3) {
      margintop = 0;
    } else if (screenWidth >= 850 && size >= 3) {
      margintop = -65;
    }
    // console.log("margintop:", margintop);
    // console.log("screenWidth:", screenWidth);
    // console.log("size:", size);
    return margintop;
  }

  function showArrows(size: number) {
    if (screenWidth > 1100 && size > 3) {
      return true;
    } else if (screenWidth > 1100 && size <= 3) {
      return false;
    } else if (screenWidth > 900 && size > 2) {
      return true;
    } else if (screenWidth > 900 && size <= 2) {
      return false;
    } else if (screenWidth <= 900 && size > 1) {
      return true;
    } else if (screenWidth <= 900 && size == 1) {
      return false;
    }
  }

  function handleDataFromSupplierInfo(data: any) {
    setCategories({
      ...categories,
      [data.category]: {
        totalPrice: data.totalPrice,
        pricePerPerson: data.pricePerPerson,
      },
    });
  }

  function formatDate(date: Moment) {
    // convert date in 05-04-2024 format, i.e. dd-mm-yyyy using dayjs
    if (!moment(date).isValid()) {
      // If date is invalid, get the current date and format it
      const currentDate = moment();
      return currentDate.format("MMM DD, YYYY");
    }

    // If the date is valid, proceed as before
    const dateObject = moment(date);
    return dateObject.format("MMM DD, YYYY");
  }

  async function requestEvent() {
    setRequestEventLoading(true);
    try {
      const requestEventData = {
        type: searchedData.eventType,
        city: searchedData.city,
        date: searchedData.date,
        timeFrom: searchedData.timeFrom,
        timeTill: searchedData.timeTill,
        noOfPersons: searchedData.noOfGuests,
        totalCostOfEvent: totalPrice,
        totalCostOfEventPerPerson: 0,
        eventServices: Object.values(categories)
          .filter((item) => item.serviceId)
          .map((item) => {
            return {
              serviceId: item.serviceId ?? "",
              noBrainerSelected: item.enableNoBrainer,
              decidedTotalCost: item.totalPrice ?? 0,
              decidedCostPerPerson: item.unitPrice ?? 0,
              detailInformation: item.addInfo?.detailedInfo,
              serviceMaxCost: Number(item.addInfo?.maxBudget ?? ""),
              eventServiceAvailability: item.addInfo
                ? [
                    {
                      fromTime: item.addInfo.timeStart,
                      tillTime: item.addInfo.timeEnd,
                    },
                  ]
                : [],
              supplierUid: item.serviceData?.supplierUid,
            };
          }),
      };

      console.log("requestEventData:", requestEventData);

      if (isEmpty(requestEventData.eventServices)) {
        throw Error("Add atleast 1 service to the event");
      }

      requestEventData.eventServices.forEach((eventService) => {
        if (isEmpty(eventService.eventServiceAvailability)) {
          throw Error("Please add time range for all selected services");
        }
      });

      const user = authContext?.user;
      if (!user) {
        toast.info("You need to login first");
        navigate("/login", {
          state: {
            fromPage: "create-event",
          },
        });
        return;
      }

      console.log("requstedEventData", requestEventData);

      const token = await user.getIdToken();

      const response = await axios.post(
        PROD_URL + "/event/create",
        requestEventData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      sessionStorage.clear();
      toast.success("Event requested successfully");
      navigate("/my-events");
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
    } finally {
      setRequestEventLoading(false);
    }
  }

  function addInformation(key: string, item: CategoryValues) {
    setInfoModalCategory(key);
    const { addInfo } = categories[key];
    console.log("add info", addInfo);

    setAddInfoData({
      maxBudget: addInfo ? addInfo.maxBudget : "",
      timeStart: addInfo ? addInfo.timeStart : "Select",
      timeEnd: addInfo ? addInfo.timeEnd : "Select",
      detailedInfo: addInfo ? addInfo.detailedInfo : "",
    });
    setShowAddInfoModal(true);
  }

  const addInfoToCategories = () => {
    if (addInfoData.timeStart == "Select" || addInfoData.timeEnd == "Select") {
      toast.error("Please select both time ranges");
      return;
    }

    if (
      moment(addInfoData.timeStart, "HH:mm").isAfter(
        moment(addInfoData.timeEnd, "HH:mm")
      )
    ) {
      toast.error("Please ensure that the end time is after the start time");
      return;
    }

    if (
      moment(addInfoData.timeStart, "HH:mm").isSame(
        moment(addInfoData.timeEnd, "HH:mm")
      )
    ) {
      toast.error("Start and end time cannot be the same");
      return;
    }

    console.log("addInfoToCategories ran");
    const updatedCategories = { ...categories };
    updatedCategories[infoModalCategory].addInfo = addInfoData;
    setCategories(updatedCategories);
    sessionStorage.setItem("categories", JSON.stringify(updatedCategories));

    let updatedServices = [...timelineServices]; // Initialize with the existing timelineServices

    Object.entries(updatedCategories)
      .filter(([key, value]) => value.addInfo)
      .forEach(([key, value]) => {
        const serviceTimeStart = moment(
          `${searchedData.date} ${value.addInfo?.timeStart}`,
          "MMM DD, YYYY HH:mm"
        ).toDate();
        const serviceTimeEnd = moment(
          `${searchedData.date} ${value.addInfo?.timeEnd}`,
          "MMM DD, YYYY HH:mm"
        ).toDate();

        const serviceId = value.serviceId;

        if (isNewService(key, updatedServices)) {
          // Service is new, add it
          updatedServices.push({
            start: serviceTimeStart,
            end: serviceTimeEnd,
            name: key,
            id: serviceId,
            type: "task",
            progress: 100,
            isDisabled: false,
            styles: {
              progressColor: COLORS[updatedServices.length],
              progressSelectedColor: COLORS[updatedServices.length],
            },
          });
          console.log(`Service with name ${key} added.`);
        } else {
          // Service already exists, update it
          updatedServices = updatedServices.map((service, index) =>
            service.name === key
              ? {
                  ...service,
                  start: serviceTimeStart,
                  end: serviceTimeEnd,
                  styles: {
                    progressColor: COLORS[index],
                    progressSelectedColor: COLORS[index],
                  },
                }
              : service
          );
          console.log(`Service with name ${key} updated.`);
        }
      });

    // Update the state and sessionStorage after all updates
    setTimelineServices(updatedServices);
    sessionStorage.setItem("timelineServices", JSON.stringify(updatedServices));

    setAddInfoData({
      maxBudget: "",
      timeStart: "Select",
      timeEnd: "Select",
      detailedInfo: "",
    });
    setShowAddInfoModal(false);
  };

  function isNewService(name: string, updatedServices: Task[]) {
    return (
      timelineServices.findIndex((service) => service.name === name) === -1
    );
  }

  function isNewServiceByCategory(name: string) {
    return (
      timelineServices.findIndex((service) => service.name === name) === -1
    );
  }

  useEffect(() => {
    if (openService == false) {
      if (
        !compareServiceArrays(searchedData.services, eventFormData.services)
      ) {
        updateSearch();
      }
    }
  }, [openService]);

  return (
    <div className="create-event-parent">
      <Navbar />
      <AddInfoModal
        searchedData={searchedData}
        infoModalCategory={infoModalCategory}
        categories={categories}
        addInfoData={addInfoData}
        setAddInfoData={setAddInfoData}
        showAddInfoModal={showAddInfoModal}
        setShowAddInfoModal={setShowAddInfoModal}
        addInfoToCategories={addInfoToCategories}
      />

      <div className="event-page">
        <div className="text-center">
          <span className="event-tag">EVENT</span>

          <p className="tagline mt-4">
            Kombinieren Sie Ihre Veranstaltung mit wenigen Klicks
          </p>

          <div className="mt-4">
            <CreateEventForm
              eventFormData={eventFormData}
              setEventFormData={setEventFormData}
              openService={openService}
              setOpenService={setOpenService}
              formFunction={updateSearch}
              screenWidth={screenWidth}
              page="create-event"
              formLoading={formLoading}
            />
          </div>

          {screenWidth > 768 && (
            <div className="mt-4">
              <button
                className="btn event-btn"
                onClick={updateSearch}
                disabled={formLoading}
              >
                {formLoading ? "Searching..." : "Update Search"}
              </button>
            </div>
          )}

          {(!isEmpty(serviceData) || !isEmpty(noBrainerData)) && (
            <>
              {!isEmpty(timelineServices) && (
                <div className="event-timeline">
                  <div
                    className="event-timeline-btn"
                    onClick={() => setTimeline(!timeline)}
                  >
                    {timeline ? (
                      <>
                        Hide timeline of event
                        <i
                          className="fas fa-chevron-up ms-2"
                          style={{
                            fontSize: "15px",
                          }}
                        ></i>
                      </>
                    ) : (
                      <>
                        See timeline of event
                        <i
                          className="fas fa-chevron-down ms-2"
                          style={{
                            fontSize: "15px",
                          }}
                        ></i>
                      </>
                    )}
                  </div>

                  <div
                    className="timeline-gantt-chart"
                    style={{
                      visibility: timeline ? "visible" : "hidden",
                      height: timeline ? "100%" : "0",
                      padding: timeline ? "5px 30px 20px" : "0",
                    }}
                  >
                    <div className="timeline-btn-div">
                      {timelineServices.map((item, index) => (
                        <button
                          key={index}
                          className="timeline-button"
                          onClick={() =>
                            addInformation(item.name, categories[item.name])
                          }
                          disabled={
                            categories[item.name]?.serviceId ? false : true
                          }
                        >
                          {categories[item.name]?.addInfo ? (
                            <MdEdit />
                          ) : (
                            <IoMdAdd />
                          )}
                        </button>
                      ))}
                    </div>
                    <div className="gantt-div">
                      <Gantt
                        tasks={timelineServices}
                        viewMode={ViewMode.Hour}
                        barCornerRadius={15}
                        TooltipContent={() => null}
                        // columnWidth={100}
                      />
                    </div>
                  </div>
                </div>
              )}
              {/* {!isEmpty(eventFormData.services) && ( */}
              <div className="services">
                <div className="row gy-4">
                  <div
                    className="col-xl-3 col-lg-4 col-md-5 order-md-2"
                    style={{ paddingTop: "13px" }}
                  >
                    <div>
                      <div className="request-event-card shadow-sm">
                        <div className="service-name">Total Event Price</div>
                        <div className="request-event-price">
                          €{calculateTotalPrice()}
                        </div>
                        {/* <div className="service-price-person">
                          €{unitPrice}/per person
                        </div> */}
                        <div>
                          <button
                            className="request-event-btn"
                            onClick={requestEvent}
                            disabled={requestEventLoading}
                          >
                            {requestEventLoading
                              ? "Requesting..."
                              : "Request Event"}
                          </button>
                        </div>
                      </div>
                      <div
                        className="add-new-service"
                        onClick={() => setOpenService(true)}
                      >
                        + Add New Service
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9 col-lg-8 col-md-7 order-md-1">
                    <div className="row gx-2">
                      <div className="prev-arrow-container col-sm-1 col-2">
                        {showArrows(Object.entries(categories).length) && (
                          <button
                            onClick={onPrevButtonClick}
                            disabled={prevBtnDisabled}
                          >
                            <IoArrowBack />
                          </button>
                        )}
                      </div>
                      <div className={`slider-container1 col-sm-10 col-8`}>
                        <div className="embla">
                          <div className="embla__viewport" ref={emblaRef1}>
                            <div className="embla__container">
                              {Object.entries(categories).map(
                                ([key, item], index) => {
                                  const { unitName, total_amount, unit_price } =
                                    getCreateEventAmount(
                                      Number(eventFormData.guests),
                                      !item.enableNoBrainer
                                        ? item.serviceData?.priceTypeSelected!
                                        : item.serviceData
                                            ?.noBrainerPriceTypeSelected!,
                                      item.serviceData!,
                                      item.addInfo?.timeStart,
                                      item.addInfo?.timeEnd
                                    );
                                  console.log(
                                    "Rendering",
                                    unitName,
                                    item.unitName
                                  );

                                  return (
                                    <div className="embla__slide">
                                      <div
                                        key={key}
                                        className={`service-card ${
                                          key == activeCategory && "active"
                                        }`}
                                        onClick={() => updateActiveCard(key)}
                                      >
                                        <div
                                          className={`sub-card ${
                                            key == activeCategory && "active"
                                          }`}
                                          style={{
                                            ...(item.background && {
                                              backgroundImage: `linear-gradient(
                                      0deg, 
                                      rgba(0, 0, 0, 0.6) 0%, 
                                      rgba(0, 0, 0, 0.6) 100%
                                    ), url(${item.background})`,
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                            }),
                                          }}
                                        >
                                          <div
                                            className="service-name"
                                            style={{
                                              ...(item.background && {
                                                color: "white",
                                              }),
                                            }}
                                          >
                                            {key}
                                          </div>
                                          <div className="icon-bg">
                                            <img
                                              // @ts-ignore
                                              src={servicesWithIcon[key]}
                                              width="20px"
                                              height="20px"
                                            />
                                          </div>

                                          {(unitName === "per person" ||
                                            unitName === "price" ||
                                            unitName ===
                                              "per hour with total") && (
                                            <div>
                                              <div
                                                className="service-price"
                                                style={{
                                                  color: `${
                                                    item.background
                                                      ? "white"
                                                      : "black"
                                                  }`,
                                                }}
                                              >
                                                €{total_amount || 0}
                                              </div>
                                              <div
                                                className="service-price-person"
                                                style={{
                                                  ...(item.background && {
                                                    color: "white",
                                                  }),
                                                }}
                                              >
                                                €{unit_price || 0}/per person
                                              </div>
                                            </div>
                                          )}
                                          {unitName === "on request" && (
                                            <div>
                                              <div
                                                className="service-price"
                                                style={{
                                                  color: `${
                                                    item.background
                                                      ? "white"
                                                      : "black"
                                                  }`,
                                                }}
                                              >
                                                –
                                              </div>
                                              <div
                                                className="service-price-person"
                                                style={{
                                                  color: `${
                                                    item.background
                                                      ? "white"
                                                      : "black"
                                                  }`,
                                                }}
                                              >
                                                <b>On Request</b>
                                              </div>
                                            </div>
                                          )}
                                          {unitName === "per hour" && (
                                            <div>
                                              <div
                                                className="service-price"
                                                style={{
                                                  color: `${
                                                    item.background
                                                      ? "white"
                                                      : "black"
                                                  }`,
                                                }}
                                              >
                                                –
                                              </div>
                                              <div
                                                className="service-price-person"
                                                style={{
                                                  ...(item.background && {
                                                    color: "white",
                                                  }),
                                                }}
                                              >
                                                €{unit_price || 0}/per hour
                                              </div>
                                            </div>
                                          )}
                                          <button
                                            className="addinfo"
                                            disabled={
                                              item.serviceId ? false : true
                                            }
                                            onClick={() =>
                                              addInformation(key, item)
                                            }
                                          >
                                            {item.addInfo
                                              ? "Edit Information"
                                              : "+ Add Information"}
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="next-arrow-container col-sm-1 col-2">
                        {showArrows(Object.entries(categories).length) && (
                          <button
                            onClick={onNextButtonClick}
                            disabled={nextBtnDisabled}
                          >
                            <IoArrowForward />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}

              <div
                className="suppliers"
                // style={{ marginTop: `${getMarginTop(categories.length)}px` }}
              >
                {!isEmpty(
                  serviceData.filter(
                    (item) =>
                      !activeCategory || item.category === activeCategory
                  )
                ) ? (
                  <div className="relevant-suppliers">
                    {/* <OwlCarousel className="owl-theme" {...supplierOptions}> */}
                    <div className="embla" ref={emblaRef2}>
                      <div className="embla__container">
                        {serviceData.map((item, index) =>
                          activeCategory && item.category != activeCategory
                            ? null
                            : (() => {
                                console.log("item", item);
                                const { unitName, total_amount, unit_price } =
                                  getCreateEventAmount(
                                    Number(eventFormData.guests),
                                    item.priceTypeSelected,
                                    item
                                  );
                                return (
                                  <div key={index} className="embla__slide">
                                    <div className="supplier-card">
                                      {isVideo(item.photoAndVideoUrls?.[0]) ? (
                                        <Player
                                          src={item.photoAndVideoUrls?.[0]}
                                          height={260}
                                          fluid={false}
                                          //@ts-ignore
                                          width="100%"
                                        >
                                          <ControlBar autoHide={false} />
                                        </Player>
                                      ) : (
                                        <div
                                          className="service-container-image"
                                          style={{
                                            ...(isEmpty(item.photoAndVideoUrls)
                                              ? {
                                                  backgroundColor: "black",
                                                }
                                              : {
                                                  backgroundImage: `url(${
                                                    item
                                                      .photoAndVideoUrls?.[0] ??
                                                    ""
                                                  })`,
                                                }),
                                          }}
                                          onClick={() =>
                                            navigate(
                                              `/event/${convertToKebabCase(
                                                item.category
                                              )}/${convertToKebabCase(
                                                item.name
                                              )}/${item.city}/${
                                                item.serviceId
                                              }`,
                                              {
                                                state: {
                                                  data: item,
                                                  searchedData,
                                                  noBrainerEnable: false,
                                                  page: "create-event",
                                                },
                                              }
                                            )
                                          }
                                        ></div>
                                      )}

                                      <div
                                        className="supplier-info-icon"
                                        onClick={() =>
                                          navigate(
                                            `/event/${convertToKebabCase(
                                              item.category
                                            )}/${convertToKebabCase(
                                              item.name
                                            )}/${item.city}/${item.serviceId}`,
                                            {
                                              state: {
                                                data: item,
                                                searchedData,
                                                noBrainerEnable: false,
                                                page: "create-event",
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="25"
                                          height="25"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M8.92995 6.588L6.63995 6.875L6.55795 7.255L7.00795 7.338C7.30195 7.408 7.35995 7.514 7.29595 7.807L6.55795 11.275C6.36395 12.172 6.66295 12.594 7.36595 12.594C7.91095 12.594 8.54395 12.342 8.83095 11.996L8.91895 11.58C8.71895 11.756 8.42695 11.826 8.23295 11.826C7.95795 11.826 7.85795 11.633 7.92895 11.293L8.92995 6.588ZM8.99995 4.5C8.99995 4.76522 8.8946 5.01957 8.70706 5.20711C8.51952 5.39464 8.26517 5.5 7.99995 5.5C7.73474 5.5 7.48038 5.39464 7.29285 5.20711C7.10531 5.01957 6.99995 4.76522 6.99995 4.5C6.99995 4.23478 7.10531 3.98043 7.29285 3.79289C7.48038 3.60536 7.73474 3.5 7.99995 3.5C8.26517 3.5 8.51952 3.60536 8.70706 3.79289C8.8946 3.98043 8.99995 4.23478 8.99995 4.5Z"
                                            fill="white"
                                          />
                                        </svg>
                                      </div>

                                      <div
                                        className="add-supplier-icon"
                                        onClick={(event) =>
                                          addServiceToEvent(item, event, false)
                                        }
                                      >
                                        <IoMdAdd color="white" size={23} />
                                      </div>

                                      <div className="supplier-text">
                                        <div className="supplier-heading-text">
                                          {item.name}
                                        </div>
                                        <div className="supplier-desc-text my-2">
                                          {item.description}
                                        </div>
                                        {unitName === "per hour" && (
                                          <div className="d-flex pricing justify-content-center">
                                            <div>
                                              <span className="pricing-price">
                                                €{unit_price}
                                              </span>
                                              <span className="supplier-light-text">
                                                /{unitName}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {unitName === "on request" && (
                                          <div className="d-flex pricing justify-content-center">
                                            <div>
                                              <span className="pricing-price">
                                                {titleCase(unitName)}
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                        {(unitName === "per person" ||
                                          unitName === "price" ||
                                          unitName ===
                                            "per hour with total") && (
                                          <div className="d-flex pricing">
                                            <div>
                                              <span className="pricing-price">
                                                €{total_amount}
                                              </span>
                                              <span className="supplier-light-text">
                                                /total
                                              </span>
                                            </div>
                                            <div className="price-vertical-line"></div>
                                            <div>
                                              <span className="pricing-price">
                                                €{unit_price}
                                              </span>
                                              <span className="supplier-light-text">
                                                /per person
                                              </span>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })()
                        )}
                      </div>
                    </div>
                    {/* </OwlCarousel> */}
                  </div>
                ) : (
                  <div className="d-flex mt-2">
                    <div className="no-match-parent-card">
                      <div className="no-match-card">
                        Sorry, no perfect match found 😞
                      </div>
                    </div>
                  </div>
                )}

                {!isEmpty(noBrainerData) &&
                  !isEmpty(
                    noBrainerData.filter(
                      (item) =>
                        !activeCategory || item.category === activeCategory
                    )
                  ) &&
                  isEmpty(
                    serviceData.filter(
                      (item) =>
                        !activeCategory || item.category === activeCategory
                    )
                  ) && (
                    <div className="alternatives mt-3">
                      <div className="heading">Alternatives on Request</div>
                      {/* <ReactOwlCarousel className="owl-theme" {...supplierOptions}> */}
                      <div className="embla" ref={emblaRef3}>
                        <div className="embla__container">
                          {noBrainerData
                            .filter(
                              (item) =>
                                !activeCategory ||
                                item.category === activeCategory
                            )
                            .map((item, index) => {
                              const { unitName, total_amount, unit_price } =
                                getCreateEventAmount(
                                  Number(eventFormData.guests),
                                  item.noBrainerPriceTypeSelected,
                                  item
                                );
                              console.log(unitName, total_amount, unit_price);
                              console.log(item);
                              return (
                                <div key={index} className="embla__slide">
                                  <div className="supplier-card">
                                    {/* <ReactOwlCarousel
                                      className="owl-theme"
                                      {...supplierOptions}
                                    > */}
                                    {/* {item.photoAndVideoUrls.map(
                                        (image: string) => ( */}
                                    {isVideo(item.photoAndVideoUrls?.[0]) ? (
                                      <Player
                                        src={item.photoAndVideoUrls?.[0]}
                                        height={260}
                                        fluid={false}
                                        //@ts-ignore
                                        width="100%"
                                      >
                                        <ControlBar autoHide={false} />
                                      </Player>
                                    ) : (
                                      <div
                                        className="service-container-image"
                                        style={{
                                          ...(isEmpty(item.photoAndVideoUrls)
                                            ? {
                                                backgroundColor: "black",
                                              }
                                            : {
                                                backgroundImage: `url(${
                                                  item.photoAndVideoUrls?.[0] ??
                                                  ""
                                                })`,
                                              }),
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/event/${convertToKebabCase(
                                              item.category
                                            )}/${convertToKebabCase(
                                              item.name
                                            )}/${item.city}/${item.serviceId}`,
                                            {
                                              state: {
                                                data: item,
                                                searchedData,
                                                noBrainerEnable: true,
                                                page: "create-event",
                                              },
                                            }
                                          )
                                        }
                                      >
                                        <div
                                          className="supplier-info-icon"
                                          onClick={() =>
                                            navigate(
                                              `/event/${convertToKebabCase(
                                                item.category
                                              )}/${convertToKebabCase(
                                                item.name
                                              )}/${item.city}/${
                                                item.serviceId
                                              }`,
                                              {
                                                state: {
                                                  data: item,
                                                  searchedData,
                                                  noBrainerEnable: true,
                                                  page: "create-event",
                                                },
                                              }
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                          >
                                            <path
                                              d="M8.92995 6.588L6.63995 6.875L6.55795 7.255L7.00795 7.338C7.30195 7.408 7.35995 7.514 7.29595 7.807L6.55795 11.275C6.36395 12.172 6.66295 12.594 7.36595 12.594C7.91095 12.594 8.54395 12.342 8.83095 11.996L8.91895 11.58C8.71895 11.756 8.42695 11.826 8.23295 11.826C7.95795 11.826 7.85795 11.633 7.92895 11.293L8.92995 6.588ZM8.99995 4.5C8.99995 4.76522 8.8946 5.01957 8.70706 5.20711C8.51952 5.39464 8.26517 5.5 7.99995 5.5C7.73474 5.5 7.48038 5.39464 7.29285 5.20711C7.10531 5.01957 6.99995 4.76522 6.99995 4.5C6.99995 4.23478 7.10531 3.98043 7.29285 3.79289C7.48038 3.60536 7.73474 3.5 7.99995 3.5C8.26517 3.5 8.51952 3.60536 8.70706 3.79289C8.8946 3.98043 8.99995 4.23478 8.99995 4.5Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </div>

                                        <div
                                          className="add-supplier-icon"
                                          onClick={(event) =>
                                            addServiceToEvent(item, event, true)
                                          }
                                        >
                                          <IoMdAdd color="white" size={23} />
                                        </div>
                                      </div>
                                    )}
                                    {/* )
                                      )} */}
                                    {/* </ReactOwlCarousel> */}

                                    <div className="supplier-text">
                                      <div className="supplier-heading-text">
                                        {item.name}
                                      </div>
                                      <div className="supplier-desc-text my-2">
                                        {item.description}
                                      </div>
                                      {unitName === "per hour" && (
                                        <div className="d-flex pricing justify-content-center">
                                          <div>
                                            <span className="pricing-price">
                                              €{unit_price}
                                            </span>
                                            <span className="supplier-light-text">
                                              /{unitName}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      {unitName === "on request" && (
                                        <div className="d-flex pricing justify-content-center">
                                          <div>
                                            <span className="pricing-price">
                                              {titleCase(unitName)}
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                      {(unitName === "per person" ||
                                        unitName === "price" ||
                                        unitName === "per hour with total") && (
                                        <div className="d-flex pricing">
                                          <div>
                                            <span className="pricing-price">
                                              €{total_amount}
                                            </span>
                                            <span className="supplier-light-text">
                                              /total
                                            </span>
                                          </div>
                                          <div className="price-vertical-line"></div>
                                          <div>
                                            <span className="pricing-price">
                                              €{unit_price}
                                            </span>
                                            <span className="supplier-light-text">
                                              /per person
                                            </span>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                      {/* </ReactOwlCarousel> */}
                    </div>
                  )}
              </div>
            </>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}
