import axios from 'axios';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import { PROD_URL } from '../helpers/constants';
import { EventService, Events, Offer } from '../screens/my-events/types';

class EventProvider {

  async getSupplierEvents(idToken: string): Promise<Events[] | null> {
    try {
        const response = await axios.get(`${PROD_URL}/events/supplier`,
        {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
        });
        if (response.status === 200) {
          return response.data;
        } else {
          console.error("Error getting events", response);
          return null;
        }
      } catch (error) {
        console.error("Error getting supplier events", error);
        return null;
      }
  }

  async getUserEvents(idToken: string): Promise<Events[] | null> {
    try {
        const response = await axios.get(PROD_URL + "/events", {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        });
        if (response.status === 200) {
          return response.data;
        } else {
          console.error("Error getting events", response);
          return null;
        }
      } catch (error) {
        console.error("Error getting user events", error);
        return null;
      }
  }

  async changeEventStatus(idToken: string, eventId: string, eventServiceId: string, eventStatus: string): Promise<EventService | null> {
    try {
        const response = await axios.get(`${PROD_URL}/event/${eventId}/eventService/${eventServiceId}/${eventStatus}`,
        {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
        });
        if (response.status === 200) {
          return response.data;
        } else {
          toast.error(response.data)
          console.error("Error changing event status", response);
          return null;
        }
      } catch (error: any) {
        toast.error(error.response.data.message)
        console.error("Error changing event status", error);
        return null;
      }
  }

  async changeEventStatusToPaid(idToken: string, eventId: string, eventServiceId: string, stripePaymentSuccess: string): Promise<EventService | null> {
    try {
        const response = await axios.get(`${PROD_URL}/event/${eventId}/eventService/${eventServiceId}/${stripePaymentSuccess}/success`,
        {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
        });
        if (response.status === 200) {
          return response.data;
        } else {
          toast.error(response.data)
          console.error("Error changing event status to paid", response);
          return null;
        }
      } catch (error: any) {
        toast.error(error.response.data.message)
        console.error("Error changing event status to paid", error);
        return null;
      }
  }

  async sendOrUpdateOffer( idToken: string, eventId: string, eventServiceId: string, offers: Offer[],): Promise<Offer[] | null> {
    try {
      const response = await axios.post(
        `${PROD_URL}/event/${eventId}/eventService/${eventServiceId}/sendOffer`,
        offers,
        {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }
      );
      if (response.status === 200) {
        console.log("send offer", response.data)
        //toast.success("Service updated successfully");
        return response.data;
      } else {
        console.error("Error sending offer", response);
        return null;
      }
    } catch (error) {
      console.error("Error sending or updating offer", error);
      toast.error("Error sending or updating offer");
      return null;
    }
  }



  

}

export default EventProvider;
