import { off } from "process";
import { Offer } from "../screens/my-events/types";
import { toast } from "react-toastify";

export function validateOffer(
  offer: Offer,
  showToast: (message: string) => void
) {
  validateStringProperty(offer.offerName, "Offer Name", showToast);
  validateStringProperty(offer.startAt, "Start At", showToast);
  validateStringProperty(offer.endAt, "End At", showToast);
  validateNumberProperty(offer.noOfPersons, "Number of Persons", showToast);
  validateNumberProperty(offer.amount, "Amount", showToast);
  validateStringProperty(offer.unit, "Unit", showToast);
  validateAllOfferFields(offer, showToast);

  // switch (offer.unit) {
  //     case "price":
  //     case "price-per-person":
  //     case "price-per-hour":
  //     case "on-request":
  //         break;
  //     default:
  //         showToast("Invalid value for 'unit'. Must be one of: price, price-per-person, price-per-hour, on-request.");
  //         throw new Error("Invalid value for 'unit'. Must be one of: price, price-per-person, price-per-hour, on-request.");
  // }
  validateNumberProperty(offer.tax, "Tax", showToast);
  if (offer.tax !== 7 && offer.tax !== 19) {
    showToast("Invalid value for 'tax'. Must be either 7 or 19.");
    throw new Error("Invalid value for 'tax'. Must be either 7 or 19.");
  }
}

function validateAllOfferFields(
  offer: Offer,
  showToast: (message: string) => void
) {
  console.log("type of  persons:", typeof offer.noOfPersons);
  console.log("type of  price:", typeof offer.amount);
  console.log("value of  price:", offer.amount);
  console.log("value of  unit:", offer.unit);

  if (offer.startAt == "Select") {
    showToast("Invalid start time");
    throw new Error("Invalid start time");
  }

  if (offer.endAt == "Select") {
    showToast("Invalid end time");
    throw new Error("Invalid end time");
  }

  if (!offer.noOfPersons || String(offer.noOfPersons) == "0") {
    showToast("Invalid number of persons");
    throw new Error("Invalid number of persons");
  }

  if (!offer.amount || String(offer.amount) == "0") {
    showToast("Invalid price");
    throw new Error("Invalid price");
  }

  if (offer.unit == "Select" || offer.unit == "On Request") {
    showToast("Invalid unit");
    throw new Error("Invalid unit");
  }
}

export function validateStringProperty(
  value: string,
  propertyName: string,
  showToast: (message: string) => void
) {
  if (!value || typeof value !== "string" || value.trim() === "") {
    showToast(`Invalid value for "${propertyName}".`);
    throw new Error(`Invalid value for "${propertyName}".`);
  }
}

export function validateNumberProperty(
  value: number,
  propertyName: string,
  showToast: (message: string) => void
) {
  if (value === undefined || isNaN(Number(value))) {
    showToast(`Invalid value for "${propertyName}".`);
    throw new Error(`Invalid value for "${propertyName}".`);
  }
}

export function validateBooleanProperty(
  value: string,
  propertyName: string,
  showToast: (message: string) => void
) {
  if (typeof value !== "boolean") {
    showToast(`Invalid value for "${propertyName}".`);
    throw new Error(`Invalid value for "${propertyName}".`);
  }
}

export const showToastFunction = (message: string) => {
  toast.error(message); // Adjust this according to your toast library API
};
