// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getMessaging } from "firebase/messaging";
import { PROD_URL } from "../helpers/constants";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = PROD_URL.includes("development")
  ? //dev
    {
      apiKey: "AIzaSyAfsZRDdB4oziMmtObJdie_DinkfMpwDGs",
      authDomain: "galacube-abe4b.firebaseapp.com",
      databaseURL: "https://galacube-abe4b.firebaseio.com",
      projectId: "galacube-abe4b",
      storageBucket: "galacube-abe4b.appspot.com",
      messagingSenderId: "875709819962",
      appId: "1:875709819962:web:83aec07e267b19de259903",
    }
  : // prod
    {
      apiKey: "AIzaSyA9zQUCMf3negBK_q65j1mKGNvLSjBI-lg",
      authDomain: "galacube-dev.firebaseapp.com",
      projectId: "galacube-dev",
      storageBucket: "galacube-dev.appspot.com",
      messagingSenderId: "561209495596",
      appId: "1:561209495596:web:cadf873a54a67d37f38189",
      measurementId: "G-TPZK22F1YS",
    };

const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "http://gala-cube.de/#/login",
  // url: "http://localhost:3000/login",
  // This must be true.
  handleCodeInApp: true,
  // dynamicLinkDomain: "",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const provider = new GoogleAuthProvider();
//const messaging = getMessaging(app);

export { auth, db, storage, provider, actionCodeSettings };
