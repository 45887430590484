import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { CgAttachment } from "react-icons/cg"; // Assuming you're using react-icons for icons
import { LuSendHorizonal } from "react-icons/lu";
import { Message, SupplierChatBoxProps, UserMessageTimeProps } from "./types";
import {
  DocumentData,
  FieldValue,
  QueryDocumentSnapshot,
  QuerySnapshot,
  addDoc,
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db, storage } from "../../utils/firebase.utils";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import profileImg from "../../assests/images/defaultprofileicon.png";

const ChatMessages: React.FC<SupplierChatBoxProps> = ({
  event,
  eventService,
  receiverUser,
}) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<Message[]>([]);
  const scroll = useRef<HTMLSpanElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [messageLoading, setMessageLoading] = useState(false);

  useEffect(() => {
    if (event) {
      const q = query(
        collection(
          db,
          "messages",
          event.eventId,
         eventService.eventServiceId
        ),
        orderBy("createdAt", "desc"),
        limit(50)
      );
      const unsubscribe = onSnapshot(
        q,
        (snapshot: QuerySnapshot<DocumentData>) => {
          // Specify the type for snapshot
          const fetchedMessages: Message[] = [];
          snapshot.forEach((doc: QueryDocumentSnapshot<DocumentData>) => {
            // Specify the type for doc
            fetchedMessages.push({ ...doc.data(), id: doc.id } as Message); // Cast doc.data() to Message type
          });
          const sortedMessages = fetchedMessages.sort(
            (a, b) => a.createdAt - b.createdAt
          );
          setMessages(sortedMessages);
        }
      );
      return () => unsubscribe();
    }
  }, [db, setMessages]);

  const sendMessage = async () => {
    // Specify the type for the event parameter
    // event.preventDefault();
    if (message.trim() === "") {
      alert("Enter valid message");
      return;
    }
    const { uid, displayName, photoURL } = auth.currentUser || {}; // Add fallback value to prevent errors
    if (!uid || !displayName) {
      console.log("no auth user", auth.currentUser);
      // Handle the case where auth.currentUser is undefined or null
      return;
    }
    setMessageLoading(true);
    try {
      // Assuming you have variables `eventId` and `serviceId` available
      let messageData: any = {
        text: message,
        name: displayName,
        avatar: photoURL ?? "",
        createdAt: serverTimestamp(),
        sendFrom: uid,
        sendTo: eventService.service.supplierUid,
      };
      if (event) {
        if (file) {
          // Upload image file to storage
          const imageUrl = await upload(
            file,
            event.eventId,
            eventService.eventServiceId
          );
          // Add imageUrl to the message data
          messageData.fileUrl = imageUrl;
        }
        // Add the message document to the messages collection with sub-collection ids
        const eventRef = doc(db, "messages", event.eventId);
        const eventServiceRef = collection(
          eventRef,
          eventService.eventServiceId
        );
        // Add the message document to the specified event service
        await addDoc(eventServiceRef, messageData);
        setFile(null);
        setMessage(""); // Clear the message input after sending
        setMessageLoading(false);
      }
      setMessageLoading(false);
    } catch (error) {
      // Handle any errors that occur during the Firestore operation
      console.error("Error adding document: ", error);
      setMessageLoading(false);
    }
  };

  const upload = async (
    videoFile: File,
    eventId: string,
    eventServiceId: string
  ): Promise<string> => {
    try {
      // Generate a unique filename for the video
      const videoName = `${Date.now()}_${videoFile.name}`;
      const storageRef = ref(
        storage,
        `chat/${eventId}/${eventServiceId}/${videoName}`
      );
      const upload = uploadBytes(storageRef, videoFile);
      // Get the download URL for the video
      await upload;

      // Get the download URL for the uploaded video
      const fileUrl = await getDownloadURL(storageRef);

      return fileUrl;
    } catch (error) {
      console.error("Error uploading file: ", error);
      throw error;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // Prevent the default form submission behavior
    sendMessage(); // Call the sendMessage function when the form is submitted
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  const handleRemoveFile = () => {
    setFile(null);
  };

  const UserMessageTime: React.FC<UserMessageTimeProps> = ({ timestamp }) => {
    if (!timestamp) {
      return null; // If timestamp is null, return null or any fallback UI
    }
    // Convert the timestamp to a Date object
    const date = timestamp.toDate();

    // Get the time portion from the Date object
    const timeString = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return <div className="user-message-time">{timeString}</div>;
  };

  return (
    <div className="supplier-chat-box supplier">
      <div className="chat-header">
        <div className="chat-header-flex">
          <img
            src={receiverUser?.photoUrl ?? profileImg}
            className="chat-supplier-img"
            alt="Customer"
          />
          <div className="">
            <div className="chat-main-heading">{receiverUser?.userName}</div>
            {/* <div className="chat-sub-heading">
                Last seen 10 min ago
              </div> */}
          </div>
        </div>
      </div>

      <div className="chat-body">
        {messageLoading ? (
          <div className="loading-content">
            <div className="text-center">
              <div>Loading...</div>
            </div>
          </div>
        ) : (
          messages.map((message, index) => (
            <div key={index}>
              {message.sendFrom != auth.currentUser?.uid && (
                <div className="supplier-msg-flex">
                  <div className="supplier-msg-time">
                    <div className="supplier-msg-name">
                      {receiverUser?.userName}
                    </div>
                    {message.fileUrl && (
                      <img
                        src={message.fileUrl}
                        alt="Image"
                        className="service-attachment-pic"
                      />
                    )}
                    <div className="supplier-message">{message.text}</div>
                    <UserMessageTime timestamp={message.createdAt} />
                  </div>
                </div>
              )}
              {message.sendFrom === auth.currentUser?.uid && (
                <div className="user-msg-flex">
                  <div className="user-msg-time">
                    {message.fileUrl && (
                      <img
                        src={message.fileUrl}
                        alt="Image"
                        className="service-attachment-pic"
                      />
                    )}
                    <div className="user-message">{message.text}</div>
                    <UserMessageTime timestamp={message.createdAt} />
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
      <span ref={scroll}></span>
        <div className="chat-footer">
      <form onSubmit={handleSubmit}>
          {file && (
            <div className="service-attachment-pic">
              <img
                className="service-attachment-pic"
                src={URL.createObjectURL(file!)}
                alt={`Uploaded `}
              />
              <div
                className="service-image-cross clickable"
                onClick={() => handleRemoveFile()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 10 10"
                  fill="#000000"
                >
                  <path
                    d="M2.91602 2.91699L7.08268 7.08366M2.91602 7.08366L7.08268 2.91699"
                    stroke="#9F9F9F"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
          )}
          <div className="chat-message-flex">
            <div className="chat-message-box form-control d-flex">
              <input
                type="text"
                placeholder="Type something here"
                className="chat-message-input"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              <label htmlFor="attachment">
                <CgAttachment
                  size={20}
                  color="gray"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </label>
              <input
                type="file"
                id="attachment"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
            <button className="message-send-btn">
              <LuSendHorizonal size={23} />
            </button>
          </div>
      </form>
        </div>
    </div>
  );
};

export default ChatMessages;
