import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { PROD_URL } from '../helpers/constants';
import axios from 'axios';

interface Category {
  categoryId: string;
  categoryName: string;
  subCategory: string[];
  features: string;
  chatBotQuestions: string[];
}

interface City {
  cityId: string;
  cityName: string;
}

interface GeneralContextType {
  categories: Category[];
  fetchCategories: () => Promise<void>;
  cities: City[];
  fetchCities: () => Promise<void>;
}

const GeneralContext = createContext<GeneralContextType | undefined>(undefined);

export const useGeneralContext = () => {
  const context = useContext(GeneralContext);
  if (!context) {
    throw new Error('useGeneralContext must be used within a CategoryProvider');
  }
  return context;
};

export const GeneralProvider = ({ children }: any) => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [cities, setCities] = useState<City[]>([]);

  useEffect(() => {
    fetchCategories();  
  }, []);

  const fetchCategories = async () => {
    try {
     const response = await axios.get(
            `${PROD_URL}/all-categories`
      );
      const data = await response.data;
      setCategories(data.categories);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchCities = async () => {
    try {
     const response = await axios.get(
            `${PROD_URL}/all-cities`
      );
      const data = await response.data;
      console.log("data getting fetched for cities")
      console.log(data)
      setCities(data.cities);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const contextValue: GeneralContextType = {
    categories,
    fetchCategories,
    cities,
    fetchCities
  };

  return <GeneralContext.Provider value={contextValue}>{children}</GeneralContext.Provider>;
};
