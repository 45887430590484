import locationIcon from "../../assests/images/service-category-icons/location.svg";
import musicIcon from "../../assests/images/service-category-icons/music-note-svgrepo-com.svg";
import cameraIcon from "../../assests/images/service-category-icons/photo.svg";
import staffIcon from "../../assests/images/service-category-icons/staff.svg";
import teamEventIcon from "../../assests/images/service-category-icons/teamevent.svg";
import cleaningIcon from "../../assests/images/service-category-icons/clean-hands-svgrepo-com.svg";
import cateringIcon from "../../assests/images/service-category-icons/catering.svg";
import transportIcon from "../../assests/images/service-category-icons/transport2.svg";
import lightningIcon from "../../assests/images/service-category-icons/lightning.svg";
import entertainmentIcon from "../../assests/images/service-category-icons/smile.svg";
import securityIcon from "../../assests/images/service-category-icons/lock.svg";
import techIcon from "../../assests/images/service-category-icons/tech.svg";

export const serviceMenu = [
  "Location",
  "Catering",
  "Team Event",
  "Staff",
  "Transport",
  "Tech Rental",
  "Music",
  "Photography & Videography",
  "Security Services",
  "Cleaning Services",
  "Lightning Services",
  "Entertainment",
];

export const servicesWithIcon = {
  Location: locationIcon,
  Catering: cateringIcon,
  "Team Event": teamEventIcon,
  Staff: staffIcon,
  Transport: transportIcon,
  "Tech Rental": techIcon,
  Music: musicIcon,
  "Photography & Videography": cameraIcon,
  "Security Services": securityIcon,
  "Cleaning Services": cleaningIcon,
  "Lightning Services": lightningIcon,
  Entertainment: entertainmentIcon,
};
