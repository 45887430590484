import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import Navbar from "../../components/navbar";
import tableImg from "../../assests/images/table_full_img.jpeg";
import EventdetailedViewModal from "../../components/event-detailed-view-modal";
import { useEvents } from "../../context/EventContext";
import { EventService, Events } from "../my-events/types";
import EventProvider from "../../providers/eventProvider";
import { toast } from "react-toastify";
import {
  eventTimeStatus,
  formatDate,
  getRemainingTime,
  sorfOffersWithTime,
} from "../../helpers/utils";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Footer from "../../components/footer";
import { AuthContext } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

export default function MyRequests() {
  const authContext = useContext(AuthContext);
  const [tabActive, setTabActive] = useState("tab1");
  const [idToken, setIdToken] = useState("");
  const { supplierEvents, fetchEventsOfSuppliers, updateEventStatusSupplier } =
    useEvents();
  const [showEventModal, setShowEventModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Events>();
  const [selectedEventService, setSelectedEventService] =
    useState<EventService>();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortedEvents, setSortedEvents] = useState<Events[]>([]);
  const [eventsLoading, setEventsLoading] = useState(false);
  const navigate = useNavigate();

  const getUrlParams = () => {
    // Get the portion of the URL after the hash
    const hashParams = window.location.hash.split("?")[1];
    const urlParams = new URLSearchParams(hashParams);
    return {
      eventId: urlParams.get("eventId"),
      eventServiceId: urlParams.get("eventServiceId"),
      eventServiceStatus: urlParams.get("eventServiceStatus"),
    };
  };

  const clearUrlParams = () => {
    // Clear query parameters from the URL
    const baseUrl = window.location.href.split("?")[0];
    window.history.replaceState({}, document.title, baseUrl);
  };

  useEffect(() => {
    initFunction();
  }, [authContext]);

  async function initFunction() {
    setEventsLoading(true);
    const user = authContext?.user;
    if (user) {
      const retrievedIdToken = await user.getIdToken();

      if (retrievedIdToken) {
        setIdToken(retrievedIdToken);
        const { eventId, eventServiceId, eventServiceStatus } = getUrlParams();
        if (eventId && eventServiceId && eventServiceStatus) {
          changeEventStatus(
            retrievedIdToken,
            eventId,
            eventServiceId,
            eventServiceStatus
          )
            .then(() => {
              clearUrlParams(); // Clear URL params after status change
            })
            .catch((error) => {
              console.error(
                "Error occurred while changing event status:",
                error
              );
            });
        }
        await fetchEventsOfSuppliers(retrievedIdToken);
      } else {
      }
    }
    setEventsLoading(false);
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    let sortedEventsTemp = supplierEvents;

    if (tabActive === "tab1") {
      sortedEventsTemp = supplierEvents;
    } else if (tabActive === "tab2") {
      sortedEventsTemp = supplierEvents.filter((event) =>
        getRemainingTime(event.eventServices[0])
      );
    } else if (tabActive === "tab3") {
      sortedEventsTemp = supplierEvents.filter(
        (event) => event.eventServices[0]?.eventServiceStatus === "ACCEPTED"
      );
    } else if (tabActive === "tab4") {
      sortedEventsTemp = supplierEvents.filter(
        (event) => event.eventServices[0]?.eventServiceStatus === "DECLINED"
      );
    } else if (tabActive === "tab5") {
      sortedEventsTemp = supplierEvents.filter(
        (event) => event.eventServices[0]?.eventServiceStatus === "SUCCESSED"
      );
    } else if (tabActive === "tab6") {
      sortedEventsTemp = supplierEvents.filter(
        (event) => !getRemainingTime(event.eventServices[0])
      );
    }

    if (tabActive === "tab1" || tabActive === "tab2") {
      sortedEventsTemp = [...sortedEventsTemp].sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        return dateB.getTime() - dateA.getTime();
      });
    }
    if (searchTerm) {
      sortedEventsTemp = sortedEventsTemp.filter((event) =>
        event.createdUser?.userName
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      );
    }

    setSortedEvents(sortedEventsTemp);
  }, [tabActive, supplierEvents]);

  async function changeEventStatus(
    retrievedIdToken: string,
    eventId: string,
    eventServiceId: string,
    eventServiceStatus: string
  ) {
    const eventProvider = new EventProvider();
    const eventService = await eventProvider.changeEventStatus(
      retrievedIdToken,
      eventId,
      eventServiceId,
      eventServiceStatus
    );
    if (eventService) {
      updateEventStatusSupplier(eventId, eventServiceId, eventServiceStatus);
      toast.success("Status updated of the event");
    }
  }

  const exportToExcel = () => {
    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Events");

    // Define the columns for the Excel sheet
    worksheet.columns = [
      { header: "Event ID", key: "eventId" },
      { header: "User UID", key: "userUid" },
      { header: "Type", key: "type" },
      { header: "City", key: "city" },
      { header: "Date", key: "date" },
      { header: "Time From", key: "timeFrom" },
      { header: "Time Till", key: "timeTill" },
      { header: "No. of Persons", key: "noOfPersons" },
      { header: "Status", key: "status" },
      { header: "Is Deleted", key: "isDeleted" },
      { header: "Created At", key: "createdAt" },
      { header: "Updated At", key: "updatedAt" },
      { header: "Total Cost Of Event", key: "totalCostOfEvent" },
      {
        header: "Total Cost Of Event Per Person",
        key: "totalCostOfEventPerPerson",
      },
      { header: "Service Name", key: "serviceName" },
      { header: "Event Service Status", key: "eventServiceStatus" },
      // Add more columns as needed from EventService, Service, and UserData interfaces...
    ];

    // Add data to the worksheet
    sortedEvents.forEach((event) => {
      const serviceName = event.eventServices
        .map((eventService) => eventService.service.name)
        .join(", ");
      const eventServiceStatus = event.eventServices
        .map((eventService) => eventService.eventServiceStatus)
        .join(", ");
      worksheet.addRow({
        eventId: event.eventId,
        userUid: event.userUid,
        type: event.type,
        city: event.city,
        date: event.date,
        timeFrom: event.timeFrom,
        timeTill: event.timeTill,
        noOfPersons: event.noOfPersons,
        status: event.status,
        isDeleted: event.isDeleted,
        createdAt: event.createdAt,
        updatedAt: event.updatedAt,
        totalCostOfEvent: event.totalCostOfEvent,
        totalCostOfEventPerPerson: event.totalCostOfEventPerPerson,
        serviceName: serviceName,
        eventServiceStatus: eventServiceStatus,
        // Add more properties as needed from EventService, Service, and UserData interfaces...
      });
    });

    // Generate Excel file
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, "requests.xlsx");
    });
  };

  return (
    <div>
      <Navbar />

      <EventdetailedViewModal
        showEventModal={showEventModal}
        setShowEventModal={setShowEventModal}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        selectedEventService={selectedEventService}
      />
      {eventsLoading ? (
        <div className="loading-content">
          <div className="text-center">
            {[1, 2, 3].map((item) => (
              <div className="spinner-grow" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ))}
            <div>Loading requests...</div>
          </div>
        </div>
      ) : (
        <div className="supplier-info">
          <div className="page-title">My Requests</div>
          <div style={{ marginTop: "30px" }} />
          <div className="services-search-box">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.5 4.25006C6.50544 4.25006 5.55161 4.64517 4.84835 5.34847C4.14509 6.05176 3.75 7.00564 3.75 8.00025C3.75 8.99486 4.14509 9.94873 4.84835 10.652C5.55161 11.3553 6.50544 11.7504 7.5 11.7504C8.49456 11.7504 9.44839 11.3553 10.1517 10.652C10.8549 9.94873 11.25 8.99486 11.25 8.00025C11.25 7.00564 10.8549 6.05176 10.1517 5.34847C9.44839 4.64517 8.49456 4.25006 7.5 4.25006ZM2.25 8.00025C2.25 7.16865 2.44753 6.34895 2.82632 5.60864C3.20512 4.86834 3.75434 4.2286 4.42875 3.74212C5.10317 3.25564 5.8835 2.93632 6.70548 2.81047C7.52745 2.68462 8.36757 2.75583 9.15664 3.01824C9.94571 3.28065 10.6612 3.72675 11.2441 4.31981C11.827 4.91287 12.2607 5.63593 12.5095 6.42943C12.7583 7.22293 12.8151 8.06419 12.6751 8.88392C12.5351 9.70365 12.2025 10.4784 11.7045 11.1444L15.5303 14.9696C15.671 15.1102 15.7501 15.301 15.7502 15.5C15.7502 15.6989 15.6713 15.8898 15.5306 16.0305C15.39 16.1712 15.1992 16.2504 15.0003 16.2504C14.8013 16.2505 14.6105 16.1715 14.4697 16.0309L10.6447 12.2057C9.86439 12.7895 8.93694 13.1445 7.96625 13.2311C6.99556 13.3176 6.01994 13.1323 5.14861 12.6958C4.27728 12.2593 3.54463 11.5888 3.0327 10.7596C2.52077 9.93026 2.24976 8.97484 2.25 8.00025Z"
                  fill="#757294"
                  fill-opacity="0.53"
                />
              </svg>
            </div>

            <input
              type="text"
              placeholder="Search Requests"
              className="services-search-input"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div style={{ marginTop: "40px" }} />

          <div className="request-tabs-section d-flex">
            <div className="d-flex">
              <div
                className={`request-tab ${tabActive == "tab1" && "active"}`}
                onClick={() => setTabActive("tab1")}
              >
                <span>All</span>
                <button className="event-numbers ms-2">
                  {supplierEvents.length}
                </button>
              </div>
              <div
                className={`request-tab ${tabActive == "tab2" && "active"}`}
                onClick={() => setTabActive("tab2")}
              >
                <span>Active Requests</span>
                <button className="event-numbers ms-2">
                  {
                    supplierEvents.filter((event) =>
                      getRemainingTime(event.eventServices[0])
                    ).length
                  }
                </button>
              </div>
              <div
                className={`request-tab ${tabActive == "tab3" && "active"}`}
                onClick={() => setTabActive("tab3")}
              >
                <span>Accepted Requests</span>
                <button className="event-numbers ms-2">
                  {
                    supplierEvents.filter(
                      (event) =>
                        event.eventServices[0]?.eventServiceStatus ===
                        "ACCEPTED"
                    ).length
                  }
                </button>
              </div>
              <div
                className={`request-tab ${tabActive == "tab4" && "active"}`}
                onClick={() => setTabActive("tab4")}
              >
                <span>Decline Requests</span>
                <button className="event-numbers ms-2">
                  {
                    supplierEvents.filter(
                      (event) =>
                        event.eventServices[0]?.eventServiceStatus ===
                        "DECLINED"
                    ).length
                  }
                </button>
              </div>
              <div
                className={`request-tab ${tabActive == "tab5" && "active"}`}
                onClick={() => setTabActive("tab5")}
              >
                <span>Completed Requests</span>
                <button className="event-numbers ms-2">
                  {
                    supplierEvents.filter(
                      (event) =>
                        event.eventServices[0]?.eventServiceStatus ===
                        "SUCCESSED"
                    ).length
                  }
                </button>
              </div>
              <div
                className={`request-tab ${tabActive == "tab6" && "active"}`}
                onClick={() => setTabActive("tab6")}
              >
                <span>Expired Requests</span>
                <button className="event-numbers ms-2">
                  {
                    supplierEvents.filter(
                      (event) => !getRemainingTime(event.eventServices[0])
                    ).length
                  }
                </button>
              </div>
            </div>
            <div className="requests-export-btn" onClick={exportToExcel}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  opacity="0.5"
                  d="M4 12.5C4 14.6217 4.84285 16.6566 6.34315 18.1569C7.84344 19.6571 9.87827 20.5 12 20.5C14.1217 20.5 16.1566 19.6571 17.6569 18.1569C19.1571 16.6566 20 14.6217 20 12.5H4Z"
                  fill="var(--primary-color)"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.5301 8.03032C15.3895 8.17077 15.1988 8.24966 15.0001 8.24966C14.8013 8.24966 14.6107 8.17077 14.4701 8.03032L12.7501 6.31032V14.5003C12.7501 14.6992 12.6711 14.89 12.5304 15.0306C12.3898 15.1713 12.199 15.2503 12.0001 15.2503C11.8012 15.2503 11.6104 15.1713 11.4698 15.0306C11.3291 14.89 11.2501 14.6992 11.2501 14.5003V6.31032L9.53009 8.03032C9.38792 8.1628 9.19987 8.23492 9.00557 8.23149C8.81127 8.22806 8.62588 8.14935 8.48847 8.01194C8.35106 7.87453 8.27234 7.68914 8.26892 7.49484C8.26549 7.30054 8.33761 7.11249 8.47009 6.97032L11.4701 3.97032C11.6107 3.82987 11.8013 3.75098 12.0001 3.75098C12.1988 3.75098 12.3895 3.82987 12.5301 3.97032L15.5301 6.97032C15.6705 7.11094 15.7494 7.30157 15.7494 7.50032C15.7494 7.69907 15.6705 7.88969 15.5301 8.03032Z"
                  fill="var(--primary-color)"
                />
              </svg>
              <span className="ms-2">Export</span>
            </div>
          </div>
          <div style={{ marginTop: "30px" }} />

          <div className="requests-table-container">
            <div className="request-table-scroll">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>CUSTOMER</th>
                    <th>LOCATION</th>
                    <th>DATE</th>
                    <th>START AT</th>
                    <th>ENDS AT</th>
                    <th>PRICE</th>
                    <th>UNIT</th>
                    <th>REQ AT</th>
                    <th>ANSWER TIME REMAIN</th>
                    <th>GUESTS</th>
                    <th>SERVICE</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(sortedEvents) &&
                    sortedEvents.map((supplierEvent) => {
                      const sortedOffers = sorfOffersWithTime(
                        supplierEvent?.eventServices?.[0]?.offers
                      );

                      return (
                        <tr>
                          {/* <div className="row-wrapper"> */}
                          <td>
                            {getRemainingTime(supplierEvent?.eventServices[0]) >
                              0 &&
                              supplierEvent?.eventServices[0]
                                .eventServiceStatus !== "DECLINED" &&
                              supplierEvent?.eventServices[0]
                                .eventServiceStatus !== "PAID" && (
                                <div
                                  className="clickable"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <path
                                      d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                                      fill="#757294"
                                    />
                                  </svg>
                                </div>
                              )}
                            <ul className="dropdown-menu">
                              <li>
                                <a
                                  className="dropdown-item clickable"
                                  style={{
                                    color: "var(--primary-color)",
                                    fontSize: "14px",
                                  }}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#eventdetailedViewModal"
                                  onClick={() =>
                                    changeEventStatus(
                                      idToken,
                                      supplierEvent?.eventId,
                                      supplierEvent?.eventServices[0]
                                        .eventServiceId,
                                      "ACCEPTED"
                                    )
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M5.25 9L5.71875 9.46875M16.5 5.25L9 12.75L8.53125 12.2812"
                                        stroke="var(--primary-color)"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                      <path
                                        d="M1.5 9L5.25 12.75L12.75 5.25"
                                        stroke="var(--primary-color)"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <span className="ms-1">Accept Request</span>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item clickable"
                                  style={{ color: "#E10000", fontSize: "14px" }}
                                  //data-bs-toggle="modal"
                                  //data-bs-target="#eventdetailedViewModal"
                                  onClick={() =>
                                    changeEventStatus(
                                      idToken,
                                      supplierEvent?.eventId,
                                      supplierEvent?.eventServices[0]
                                        .eventServiceId,
                                      "DECLINED"
                                    )
                                  }
                                >
                                  <div className="d-flex align-items-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 18 18"
                                      fill="none"
                                    >
                                      <path
                                        d="M4.5 14.25C4.5 14.6478 4.65804 15.0294 4.93934 15.3107C5.22064 15.592 5.60218 15.75 6 15.75H12C12.3978 15.75 12.7794 15.592 13.0607 15.3107C13.342 15.0294 13.5 14.6478 13.5 14.25V5.25H4.5V14.25ZM6 6.75H12V14.25H6V6.75ZM11.625 3L10.875 2.25H7.125L6.375 3H3.75V4.5H14.25V3H11.625Z"
                                        fill="#E10000"
                                      />
                                    </svg>
                                    <span className="ms-1">
                                      Decline Request
                                    </span>
                                  </div>
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item clickable"
                                  style={{ color: "#000000", fontSize: "14px" }}
                                  onClick={() => {
                                    setSelectedEventService(
                                      supplierEvent.eventServices[0]!
                                    );
                                    setSelectedEvent(supplierEvent);
                                    setShowEventModal(true);
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="18"
                                      height="18"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="feather feather-eye"
                                    >
                                      <path d="M12 4c-3.309 0-6 2.691-6 6s2.691 6 6 6 6-2.691 6-6-2.691-6-6-6z" />
                                      <circle cx="12" cy="12" r="3" />
                                    </svg>
                                    <span className="ms-1">View Details</span>
                                  </div>
                                </a>
                              </li>
                            </ul>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <img
                                src={supplierEvent?.createdUser?.photoUrl}
                                className="table-img"
                              />
                              <span className="ms-2">
                                {supplierEvent?.createdUser?.userName}
                              </span>
                            </div>
                          </td>
                          <td>{supplierEvent?.city}</td>
                          <td>{formatDate(supplierEvent?.date)}</td>
                          <td>
                            {/* note: time changed by amaaz */}
                            <div>
                              {sortedOffers[sortedOffers.length - 1]?.startAt ??
                                ""}
                            </div>
                            {/* <div style={{ marginTop: "10px" }} />
                      <div>{supplierEvent?.timeTill}</div> */}
                          </td>
                          <td>
                            <div>
                              {sortedOffers[sortedOffers.length - 1]?.endAt ??
                                ""}
                            </div>
                            {/* <div style={{ marginTop: "10px" }} />
                      <div>20:00PM</div> */}
                          </td>
                          <td>
                            {sortedOffers[sortedOffers.length - 1]?.amount}
                          </td>
                          <td>{sortedOffers[sortedOffers.length - 1]?.unit}</td>
                          <td>{formatDate(supplierEvent?.createdAt)}</td>
                          <td>
                            <div className="d-flex align-items-center justify-content-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="14"
                                viewBox="0 0 15 14"
                                fill="none"
                              >
                                <path
                                  d="M13.0891 7.58398C13.0891 8.27342 12.944 8.95611 12.662 9.59307C12.3801 10.23 11.9669 10.8088 11.446 11.2963C10.9251 11.7838 10.3067 12.1705 9.62616 12.4344C8.94557 12.6982 8.21613 12.834 7.47946 12.834C6.7428 12.834 6.01335 12.6982 5.33277 12.4344C4.65218 12.1705 4.03378 11.7838 3.51288 11.2963C2.99199 10.8088 2.57879 10.23 2.29688 9.59307C2.01497 8.95611 1.86987 8.27342 1.86987 7.58398C1.86987 6.1916 2.46088 4.85624 3.51288 3.87167C4.56489 2.88711 5.99171 2.33398 7.47946 2.33398C8.96722 2.33398 10.394 2.88711 11.446 3.87167C12.498 4.85624 13.0891 6.1916 13.0891 7.58398Z"
                                  stroke="#FB5A00"
                                  stroke-width="1.5"
                                />
                                <path
                                  d="M7.47949 7.58333V5.25"
                                  stroke="#FB5A00"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6.23291 1.16699H8.72606"
                                  stroke="#FB5A00"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                              <div
                                className="remaining-time-text ms-1"
                                style={{ color: "#fb5a00" }}
                              >
                                {eventTimeStatus(
                                  supplierEvent.eventServices[0]!
                                )}
                              </div>
                            </div>
                          </td>
                          <td>{supplierEvent?.noOfPersons}</td>
                          <td>
                            {supplierEvent?.eventServices[0]?.service.name}
                          </td>
                          <td>
                            <div className="status-tag">
                              {
                                supplierEvent?.eventServices[0]
                                  ?.eventServiceStatus
                              }
                            </div>
                            {/* <div className="d-flex align-items-center justify-content-center mt-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="14"
                          viewBox="0 0 15 14"
                          fill="none"
                        >
                          <path
                            d="M13.0891 7.58398C13.0891 8.27342 12.944 8.95611 12.662 9.59307C12.3801 10.23 11.9669 10.8088 11.446 11.2963C10.9251 11.7838 10.3067 12.1705 9.62616 12.4344C8.94557 12.6982 8.21613 12.834 7.47946 12.834C6.7428 12.834 6.01335 12.6982 5.33277 12.4344C4.65218 12.1705 4.03378 11.7838 3.51288 11.2963C2.99199 10.8088 2.57879 10.23 2.29688 9.59307C2.01497 8.95611 1.86987 8.27342 1.86987 7.58398C1.86987 6.1916 2.46088 4.85624 3.51288 3.87167C4.56489 2.88711 5.99171 2.33398 7.47946 2.33398C8.96722 2.33398 10.394 2.88711 11.446 3.87167C12.498 4.85624 13.0891 6.1916 13.0891 7.58398Z"
                            stroke="#C092E4"
                            stroke-width="1.5"
                          />
                          <path
                            d="M7.47949 7.58333V5.25"
                            stroke="#C092E4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M6.23291 1.16699H8.72606"
                            stroke="#C092E4"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                        <div
                          className="remaining-time-text ms-1"
                          style={{ color: "#C092E4" }}
                        >
                          12h 55m
                        </div>
                      </div> */}
                          </td>
                          {/* </div> */}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
}
