import axios from 'axios';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import { SupplierService } from '../components/add-new-service-modal/types';
import { PROD_URL } from '../helpers/constants';

class ServiceProvider {
  async uploadServicePhotos(serviceId: string, supplierService: SupplierService, photoFiles: File[], idToken: string): Promise<SupplierService | null> {
    try {
      const formData = new FormData();
      photoFiles.forEach((file, index) => {
        formData.append(`files`, file);
      });
      const response = await axios.post(
        `${PROD_URL}/service/${serviceId}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      if (response.status === 200) {
        supplierService.photoAndVideoUrls = [
          ...(supplierService.photoAndVideoUrls || []),
          ...(response.data.uploadedUrls || []),
        ];
        //toast.success("Service gallery uploaded");
        return supplierService;
      } else {
        console.error("Error uploading service photos", response);
        return null;
      }
    } catch (error) {
      console.error("Error uploading service photos", error);
      toast.error("Error uploading service photos");
      return null;
    }
  }

  async uploadChatDocs(serviceId: string, supplierService: SupplierService, chatBotDocs: File[], idToken: string): Promise<SupplierService | null> {
    try {
        const formData = new FormData();
        chatBotDocs.forEach((file, index) => {
          formData.append(`files`, file);
        });
        const response = await axios.post(
          `${PROD_URL}/service/${serviceId}/chatDocsUpload`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );
        if (response.status === 200) {
          supplierService.chatBotDocUrls = [
            ...(supplierService.chatBotDocUrls || []),
            ...(response.data.uploadedUrls || []),
          ];
          //toast.success("Chat documents uploaded");
          return supplierService;
        } else {
          console.error("Error uploading chat docs", response);
          return null;
        }
      } catch (error) {
        console.error("Error uploading chat docs", error);
        toast.error("Error uploading chat docs");
        return null;
      }
  }

  async getMyServices(idToken: string): Promise<SupplierService[] | null> {
    try {
        const response = await axios.get(`${PROD_URL}/services`,
        {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${idToken}`,
            },
        });
        console.log("Fetching supplier services")
        console.log(response.data)
        if (response.status === 200) {
          return response.data.services;
        } else {
          console.error("Error getting services", response);
          return null;
        }
      } catch (error) {
        console.error("Error getting supplier services", error);
        return null;
      }
  }

  async updateService(updatedService: SupplierService, idToken: string): Promise<SupplierService | null> {
    try {
      const response = await axios.put(
        `${PROD_URL}/service/${updatedService.serviceId}`,
        updatedService,
        {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }
      );
      if (response.status === 200) {
        console.log("updated service from response", response.data)
        //toast.success("Service updated successfully");
        return response.data;
      } else {
        console.error("Error updating service", response);
        return null;
      }
    } catch (error) {
      console.error("Error updating service", error);
      toast.error("Error updating service");
      return null;
    }
  }
  
  async deleteFiles(serviceId: string, fileNamesToDelete: string[], folderName: string, idToken: string): Promise<boolean> {
    try {
      const response = await axios.post(
        `${PROD_URL}/service/${serviceId}/deleteFiles`,
        {
          fileNamesToDelete,
          folderName
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`
          }
        }
      );
      console.log("filenames to delete", fileNamesToDelete)
      console.log("Deleting files")
      console.log(response)
      if (response.status === 200) {
        //toast.success("Files deleted successfully");
        return true;
      } else {
        console.error("Error deleting files", response);
        return false;
      }
    } catch (error) {
      console.error("Error deleting files", error);
      toast.error("Error deleting files");
      return false;
    }
  }

  async deleteService(serviceId: string, idToken: string): Promise<boolean> {
    try {
        const response = await axios.delete(
            `${PROD_URL}/service/${serviceId}`,
            {
                headers: {
                    Authorization: `Bearer ${idToken}`
                }
            }
        );
        if (response.status === 200) {
            console.log("Service deleted successfully");
            // Assuming response.data contains the deleted service details
            return true;
        } else {
            console.error("Error deleting service:", response);
            // Assuming you want to return false on failure
            return false;
        }
    } catch (error) {
        console.error("Error deleting service:", error);
        // Assuming you want to return false on failure
        return false;
    }
  }

  async checkAccountStripeStatus(stripeAccountId: string, eventId: string, eventServiceId: string, apiToken: string): Promise<boolean | null> {
    try {
        const response = await axios.get(`${PROD_URL}/check-account-status/${stripeAccountId}/${eventId}/${eventServiceId}`,
        {
          headers: {
              Authorization: `Bearer ${apiToken}`
          }
        }
        );
        console.log(response)
        if (response.status === 200) {
          return response.data.connected;
        } else {
          toast.error(response.data)
          console.error("Error getting stripe account status", response);
          return null;
        }
      } catch (error: any) {
        toast.error(error.response.data.message)
        console.error("Error getting stripe account status", error);
        return null;
      }
  }

  async sendEmailForStripeEnable( eventId: string, eventServiceId: string, apiToken: string): Promise<boolean | null> {
    try {
        const response = await axios.get(`${PROD_URL}/send-email-payment/${eventId}/${eventServiceId}`,
        {
          headers: {
              Authorization: `Bearer ${apiToken}`
          }
        }
        );
        console.log(response)
        if (response.status === 200) {
          return response.data.connected;
        } else {
          toast.error(response.data)
          console.error("Error sending email to enable payment", response);
          return null;
        }
      } catch (error: any) {
        toast.error(error.response.data.message)
        console.error("Error sending email to enable payment", error);
        return null;
      }
  }

  async stripeConnectLink (stripeAccountId: string): Promise<string | null>  {
    try {
      const response = await axios.get(
        `${PROD_URL}/create-account-link/${stripeAccountId}`
      );
      if (response.status === 200) {
        const supplierLinkDashboard = response.data;
        toast.success(
          "Redirecting you to stripe dashboard to complete the process"
        );
        return supplierLinkDashboard.url;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
      return null
    }
  };

}

export default ServiceProvider;