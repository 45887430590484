import { createContext, useState, useContext } from "react";
import ChatbotServiceProvider from "../providers/chatbotServiceProvider";
import moment from "moment";
import { AuthContext, useAuth } from "./AuthContext";

interface Message {
  id: number;
  text: string;
  sender: "user" | "bot";
  time: string;
}
interface ChatbotContextType {
  messages: Message[];
  lastServiceUid?: string;
  sendMessage: (
    message: string,
    supplierUid: string,
    serviceUid: string
  ) => void;
}

const ChatbotContext = createContext<ChatbotContextType | undefined>(undefined);

export const useChatbot = () => {
  const context = useContext(ChatbotContext);
  if (!context) {
    throw new Error("useChatbot must be used within a ChatbotProvider");
  }
  return context;
};
export const ChatbotProvider = ({ children }: any) => {
  const initialMessages: Message[] = [
    {
      id: 0,
      text: "Hi there, how can I help you?",
      sender: "bot",
      time: moment().format("DD-MM-YYYY HH:mm"),
    },
  ];
  const { user } = useAuth();
  const [messages, setMessages] = useState<Message[]>(initialMessages);
  const [lastServiceUid, setLastServiceUid] = useState<string>();
  const [lastSupplierUid, setLastSupplierUid] = useState<string | null>(null);

  const chatbotServiceProvider = new ChatbotServiceProvider();

  const sendMessage = async (
    message: string,
    supplierUid: string,
    serviceUid: string
  ) => {
    const idToken = (await user?.getIdToken()) ?? "";

    // Check if the incoming service and supplier IDs match the stored ones
    if (lastServiceUid !== serviceUid || lastSupplierUid !== supplierUid) {
      // Reset messages if they do not match
      // setMessages(initialMessages);
      // Update the stored service and supplier IDs
      setLastServiceUid(serviceUid);
      setLastSupplierUid(supplierUid);
    }

    if (supplierUid && serviceUid) {
      const chatMessages = messages.map((message) => ({
        role: message.sender === "user" ? "user" : "assistant",
        content: message.text,
      }));

      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: prevMessages.length + 1,
          text: message,
          sender: "user",
          time: moment().format("DD-MM-YYYY HH:mm"),
        },
      ]);

      try {
        const botResponse = await chatbotServiceProvider.sendMessage(
          message,
          supplierUid,
          serviceUid,
          idToken,
          chatMessages
        );
        // Add the actual bot response
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            id: prevMessages.length + 1,
            text: botResponse,
            sender: "bot",
            time: moment().format("DD-MM-YYYY HH:mm"),
          },
        ]);

        // Do not set setIsBotTyping(false) here
      } catch (error) {
        console.error("Error sending message:", error);
      }
    } else {
      console.error("ID token is not available.");
    }
  };
  return (
    <ChatbotContext.Provider value={{ messages, sendMessage, lastServiceUid }}>
      {children}
    </ChatbotContext.Provider>
  );
};
