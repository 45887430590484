import { isEmpty } from "lodash";
import moment from "moment";
import {
  Events,
  EventService,
  Offer,
  Service,
} from "../screens/my-events/types";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import { start } from "repl";
import { SupplierService } from "../components/add-new-service-modal/types";
import { supportedVideoExtensions } from "./constants";

export function getStartEndTime(event: Events, eventService: EventService) {
  if (event.date) {
    const datePart = moment(event.date).format("YYYY-MM-DD");
    const startTime = !isEmpty(eventService.eventServiceAvailability)
      ? moment(
          `${datePart}T${eventService.eventServiceAvailability[0].fromTime}`
        )
      : null;
    const endTime = !isEmpty(eventService.eventServiceAvailability)
      ? moment(
          `${datePart}T${eventService.eventServiceAvailability[0].tillTime}`
        )
      : null;
    return { startTime, endTime };
  }
  return {};
}

export function getTimes(date: string, offer: Offer) {
  const datePart = moment(date).format("YYYY-MM-DD");
  const startTime = moment(`${datePart}T${offer.startAt}`).toDate();
  const endTime = moment(`${datePart}T${offer.endAt}`).toDate();
  return { startTime, endTime };
}

// export function calculateRemainingTime(creationTime: string) {
//   const currentTime = moment();
//   const expirationTime = moment(creationTime).add(24, "hours"); // Validity of 24 hours

//   const difference = expirationTime.diff(currentTime);
//   if (difference <= 0) {
//     return " Expired";
//   }

//   const duration = moment.duration(difference);
//   const hours = duration.hours();
//   const minutes = duration.minutes();
//   const seconds = duration.seconds();

//   return ` ${hours}h ${minutes}min ${seconds}sec`;
// }

export function checkTokenExpiry(token: string) {
  try {
    const decoded = jwtDecode(token);
    console.log("decoded:", decoded);
    const exp = decoded.exp; // Expiration time (in seconds)
    const now = Date.now() / 1000; // Current time (in seconds)

    if ((exp as number) < now) {
      return false;
    } else {
      return true;
    }
  } catch (error: any) {
    // toast.error(error.message);
    console.error("Error decoding token:", error);
  }
}

export function getRemainingTime(eventService: EventService) {
  const updatedAtMilliseconds =
    eventService?.updatedAt._seconds * 1000 +
    eventService?.updatedAt._nanoseconds / 1000000 +
    24 * 60 * 60 * 1000;
  const currentTimeMilliseconds = Date.now();

  const timeDifference = updatedAtMilliseconds - currentTimeMilliseconds;
  return timeDifference;
}

export function eventTimeStatus(eventService: EventService) {
  const timeDifference = getRemainingTime(eventService);
  if (timeDifference <= 24 * 60 * 60 * 1000 && timeDifference >= 0) {
    const secondsDifference = Math.floor(timeDifference / 1000);

    //const days = Math.floor(secondsDifference / (3600 * 24));
    const hours = Math.floor((secondsDifference % (3600 * 24)) / 3600);
    const minutes = Math.floor((secondsDifference % 3600) / 60);
    const seconds = secondsDifference % 60;

    // Construct the remaining time string
    let remainingTime = "";
    // if (days > 0) {
    //     remainingTime += `${days} day${days > 1 ? 's' : ''}, `;
    // }
    remainingTime += `${hours}h ${minutes} min ${seconds} sec`;

    return remainingTime;
  } else {
    return "Time Passed";
  }
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString("en-US", {
    timeZone: "Europe/Berlin",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formattedDate;
};

export function getPerOfferTotalWithTax(offer: Offer) {
  const amount = getAmount(offer);
  const taxAmount = getTaxAmount(offer, amount);
  return Number(amount + taxAmount).toFixed(2);
}

export function getTaxAmount(offer: Offer, amount: number) {
  const taxRate = offer.tax / 100;
  const taxAmount = amount * taxRate;
  return Number(taxAmount.toFixed(2));
}

export function getTotalCost(eventService: EventService) {
  let totalSum = 0;
  if (eventService) {
    eventService.offers.forEach((offer) => {
      const amount = getAmount(offer);
      const taxAmount = getTaxAmount(offer, amount);
      totalSum += amount + taxAmount;
    });
  }
  return totalSum.toFixed(2);
}

export function getTotalCostEvent(event: Events) {
  let totalSum = 0;
  if (event) {
    if (event) {
      event.eventServices.forEach((eventService) => {
        eventService?.offers.forEach((offer) => {
          const amount = getAmount(offer);
          const taxAmount = getTaxAmount(offer, amount);
          totalSum += amount + taxAmount;
        });
      });
    }
    return totalSum.toFixed(2);
  }
}

export function getNetCost(eventService: EventService) {
  let netSum = 0;
  if (eventService) {
    if (eventService.offers) {
      eventService.offers.forEach((offer) => {
        const amount = getAmount(offer);
        netSum += amount;
      });
    }
  }
  return Number(netSum.toFixed(2));
}

export function getTaxCost(eventService: EventService) {
  let taxSum = 0;
  if (eventService) {
    eventService?.offers.forEach((offer) => {
      const amount = getAmount(offer);
      const taxAmount = getTaxAmount(offer, amount);
      taxSum += taxAmount;
    });
  }
  return Number(taxSum.toFixed(2));
}

export function getNetCostEvent(event: Events) {
  let netSum = 0;
  if (event) {
    event.eventServices.forEach((eventService) => {
      eventService.offers.forEach((offer) => {
        const amount = getAmount(offer);
        netSum += amount;
      });
    });
  }
  return Number(netSum.toFixed(2));
}

export function getTaxCostEvent(event: Events) {
  let taxSum = 0;
  if (event) {
    event.eventServices.forEach((eventService) => {
      eventService.offers.forEach((offer) => {
        const amount = getAmount(offer);
        const taxAmount = getTaxAmount(offer, amount);
        taxSum += taxAmount;
      });
    });
  }
  return Number((Math.round(taxSum * 100) / 100).toFixed(2));
}

export function getAmount(offer: Offer): number {
  let amount = offer.amount;
  switch (offer.unit) {
    case "Per Person":
      amount = amount * offer.noOfPersons;
      break;
    case "Per Hour":
      const startTimeParts = offer.startAt.split(":").map(Number);
      const endTimeParts = offer.endAt.split(":").map(Number);

      const startAt = new Date(0, 0, 0, startTimeParts[0], startTimeParts[1]);
      const endAt = new Date(0, 0, 0, endTimeParts[0], endTimeParts[1]);

      let timeDiffMs = endAt.getTime() - startAt.getTime();
      if (timeDiffMs < 0) {
        timeDiffMs += 24 * 60 * 60 * 1000; // 24 hours in milliseconds
      }
      const timeDiffHours = timeDiffMs / (1000 * 60 * 60);
      amount = amount * timeDiffHours;
      break;
  }
  return Number(amount);
}

export function compareServiceArrays(arr1: string[], arr2: string[]): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export function getCreateEventAmount(
  noOfGuests: number,
  selected_payment_type: string,
  service: Service,
  timeStart?: string,
  timeFrom?: string
): any {
  let total_amount = 0;
  let unit_price = 0;
  let unitName = "price";
  let hoursDifference = 0;
  if (timeStart && timeFrom) {
    const startTimeParts = timeStart.split(":").map(Number);
    const endTimeParts = timeFrom.split(":").map(Number);

    const startAt = new Date(0, 0, 0, startTimeParts[0], startTimeParts[1]);
    const endAt = new Date(0, 0, 0, endTimeParts[0], endTimeParts[1]);
    const millisecondsDifference = endAt.getTime() - startAt.getTime();
    hoursDifference = millisecondsDifference / (1000 * 60 * 60);
  }
  console.log("selected type");
  console.log(selected_payment_type);
  switch (selected_payment_type) {
    case "price-per-hour":
      if (hoursDifference != 0) {
        console.log("hours difference", hoursDifference);
        total_amount = hoursDifference * service.pricePerHour;
        console.log("total amount", total_amount);
        unitName = "per hour with total";
        unit_price = total_amount / noOfGuests;
      } else {
        unitName = "per hour";
        unit_price = service.pricePerHour;
      }
      break;
    case "on-request":
      total_amount = service.onRequestPrice;
      unit_price = 0;
      unitName = "on request";
      break;
    case "price-per-person":
      // console.log("utility")
      // console.log(noOfGuests)
      // console.log(service)
      total_amount = noOfGuests * service.pricePerPerson;
      unit_price = service.pricePerPerson;
      unitName = "per person";
      break;
    case "price":
      total_amount = service.totalPrice;
      unit_price =
        noOfGuests !== 0 ? service.totalPrice / noOfGuests : service.totalPrice;

      console.log(total_amount, unitName);
      unitName = "price";
      break;
    case "no-brainer-price":
      total_amount = service.noBrainerTotalPrice;
      unit_price =
        noOfGuests !== 0
          ? service.noBrainerTotalPrice / noOfGuests
          : service.noBrainerTotalPrice;
      unitName = "price";
      break;
    case "no-brainer-price-per-person":
      total_amount = noOfGuests * service.noBrainerPricePerPerson;
      unit_price = service.noBrainerPricePerPerson;
      unitName = "per person";
      break;
    case "no-brainer-price-on-request":
      total_amount = 0;
      unit_price = 0;
      unitName = "on request";
      break;
    case "no-brainer-price-per-hour":
      if (hoursDifference != 0) {
        console.log("hours difference", hoursDifference);
        total_amount = hoursDifference * service.noBrainerPricePerHour;
        console.log("total amount", total_amount);
        unitName = "per hour with total";
        unit_price = total_amount / noOfGuests;
      } else {
        unitName = "per hour";
        unit_price = service.noBrainerPricePerHour;
      }
      break;
  }
  return {
    unitName: unitName,
    total_amount: total_amount,
    unit_price: unit_price.toFixed(2),
  };
}

export function getSupplierServiceAmount(
  noOfGuests: number,
  selected_payment_type: string,
  service: SupplierService,
  timeStart?: string,
  timeFrom?: string
): any {
  let total_amount = 0;
  let unit_price = 0;
  let unitName = "price";
  let hoursDifference = 0;
  if (timeStart && timeFrom) {
    const startTimeParts = timeStart.split(":").map(Number);
    const endTimeParts = timeFrom.split(":").map(Number);

    const startAt = new Date(0, 0, 0, startTimeParts[0], startTimeParts[1]);
    const endAt = new Date(0, 0, 0, endTimeParts[0], endTimeParts[1]);
    const millisecondsDifference = endAt.getTime() - startAt.getTime();
    hoursDifference = millisecondsDifference / (1000 * 60 * 60);
  }

  switch (selected_payment_type) {
    case "price-per-hour":
      if (hoursDifference != 0) {
        console.log("hours difference", hoursDifference);
        total_amount = hoursDifference * service.pricePerHour!;
        console.log("total amount", total_amount);
        unitName = "per hour with total";
        unit_price = total_amount / noOfGuests;
      } else {
        unitName = "per hour";
        unit_price = service.pricePerHour!;
      }
      break;
    case "on-request":
      total_amount = service.onRequestedPrice!;
      unit_price = 0;
      unitName = "on request";
      break;
    case "price-per-person":
      // console.log("utility")
      // console.log(noOfGuests)
      // console.log(service)
      total_amount = noOfGuests * service.pricePerPerson!;
      unit_price = service.pricePerPerson!;
      unitName = "per person";
      break;
    case "price":
      total_amount = service.totalPrice!;
      unit_price =
        noOfGuests !== 0
          ? service.totalPrice! / noOfGuests
          : service.totalPrice!;
      unitName = "price";
      break;
    case "no-brainer-price":
      total_amount = service.noBrainerTotalPrice!;
      unit_price =
        noOfGuests !== 0
          ? service.noBrainerTotalPrice! / noOfGuests
          : service.noBrainerTotalPrice!;
      unitName = "price";
      break;
    case "no-brainer-price-per-person":
      total_amount = noOfGuests * service.noBrainerPricePerPerson!;
      unit_price = service.noBrainerPricePerPerson!;
      unitName = "per person";
      break;
    case "no-brainer-price-on-request":
      total_amount = 0;
      unit_price = 0;
      unitName = "on request";
      break;
    case "no-brainer-price-per-hour":
      if (hoursDifference != 0) {
        console.log("hours difference", hoursDifference);
        total_amount = hoursDifference * service.noBrainerPricePerHour!;
        console.log("total amount", total_amount);
        unitName = "per hour with total";
        unit_price = total_amount / noOfGuests;
      } else {
        unitName = "per hour";
        unit_price = service.noBrainerPricePerHour!;
      }
      break;
  }
  return {
    unitName: unitName,
    total_amount: total_amount,
    unit_price: unit_price.toFixed(2),
  };
}

export function titleCase(str: string) {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

export function clearSessionStorage() {
  sessionStorage.removeItem("categories");
  sessionStorage.removeItem("activeCategory");
  sessionStorage.removeItem("selectedIndex");
  sessionStorage.removeItem("timelineServices");
  sessionStorage.removeItem("noBrainerData");
  sessionStorage.removeItem("serviceData");
  sessionStorage.removeItem("searchedData");
}

export function convertToKebabCase(str: string) {
  return str ? str.replace(/\s+/g, "-").toLowerCase() : "";
}

export function convertToSnakeCase(str: string) {
  return str ? str.replace(/\s+/g, "_").toLowerCase() : "";
}

export function convertToTitleCase(str?: string) {
  return str
    ? str
        .split("-")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ")
    : "";
}

export const handleCopyClick = (value: string) => {
  if (navigator.clipboard) {
    navigator.clipboard
      .writeText(value)
      .then(() => {
        // toast.success("Shareable link copied to the clipboard!");
      })
      .catch((err) => {
        toast.error("Failed to copy link: " + err.message);
        console.log("Failed to copy link: ", err);
      });
  } else {
    // Fallback for browsers that don't support Clipboard API
    try {
      const textArea = document.createElement("textarea");
      textArea.value = value;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand("copy");
      document.body.removeChild(textArea);
      // toast.success("Shareable link copied to the clipboard!");
    } catch (err: any) {
      toast.error("Failed to copy link: " + err.message);
      console.log("Failed to copy link: ", err);
    }
  }
};

export function sorfOffersWithTime(offers: Offer[]) {
  if (offers.length == 1) return offers;
  // Assuming createdAt is a string in ISO format or a Date object
  return offers.sort(
    (a, b) =>
      new Date(String(a.createdAt)).getTime() -
      new Date(String(b.createdAt)).getTime()
  );
}


// export function getFileExtension(url: string) {
//   const pathname = new URL(url).pathname;
//   const lastDotIndex = pathname.lastIndexOf('.');
  
//   if (lastDotIndex === -1) {
//     return 'No extension found';
//   }
  
//   return pathname.substring(lastDotIndex + 1);
// }

export const getFileExtension = (url: string): string => {
  // Ensure the URL has a valid format and extract the extension
  const extension = url.split('?')[0].split('.').pop()?.toLowerCase() || '';
  return extension;
}

export const isImage = (url: string): boolean => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const extension = getFileExtension(url);
  console.log("URL for extension:", url);
  console.log("Extracted extension:", extension);
  return imageExtensions.includes(extension);
}

export const isVideo = (url: string): boolean => {
  // const videoExtensions = ['mp4', 'webm', 'ogg'];
  const extension = getFileExtension(url);
  console.log("Extracted extension:", extension);
  return supportedVideoExtensions.includes(extension);
}

export const isPdf = (url: string): boolean => {
  const extension = getFileExtension(url);
  console.log("Extracted extension:", extension);
  return extension === 'pdf';
}

export const isCsv = (url: string): boolean => {
  const extension = getFileExtension(url);
  console.log("Extracted extension:", extension);
  return extension === 'csv';
}

export const isJson = (url: string): boolean => {
  const extension = getFileExtension(url);
  console.log("Extracted extension:", extension);
  return extension === 'json';
}

export const getFileName = (url: string): string => {
  // Remove query parameters if present
  const cleanedUrl = url.split('?')[0];
  // Extract the file name from the cleaned URL
  const fileName = cleanedUrl.substring(cleanedUrl.lastIndexOf('/') + 1);
  return fileName;
}