import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assests/images/logo_without_bg.png";
import profileImg from "../../assests/images/defaultprofileicon.png";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { IoMdNotificationsOutline } from "react-icons/io";
import { UserData } from "../../screens/signup-login/types";
import { toast } from "react-toastify";
import { auth } from "../../utils/firebase.utils";
import { NavbarProps } from "./types";
import { SWITCH_URL } from "../../helpers/constants";
import { AuthContext } from "../../context/AuthContext";

export default function Navbar({ page }: NavbarProps) {
  const authContext = useContext(AuthContext);
  const [clicked, setClicked] = useState(false);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState("");
  const [idToken, setIdToken] = useState(localStorage.getItem("idToken") ?? "");
  const [user, setUser] = useState(authContext?.user);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    setUser(authContext?.user);
  }, [authContext]);

  const updateScreenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateScreenSize);

    return () => {
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  const logout = async () => {
    await auth.signOut();
    // Clear user data from local storage
    localStorage.clear();
    sessionStorage.clear();
    toast.success("Logged out successfully");
    navigate("/login");
  };

  return (
    <>
      <nav
        className={`${page != "landing" && "sticky-top"}`}
        style={{
          ...(page == "landing" && {
            background: "transparent",
            boxShadow: "none",
          }),
        }}
      >
        <Link to="/" className="logoname d-flex align-items-center">
          <img src={logo} className="website-logo" />{" "}
          <div className="ms-2">gala cube</div>
        </Link>

        <div>
          {!user ? (
            <div className="d-flex">
              <a
                className="signupBtn"
                href={SWITCH_URL}
                style={{ marginLeft: 0 }}
              >
                Switch to Artist Cube
              </a>
              <button onClick={() => navigate("/login")} className="loginBtn">
                Log In
              </button>
              <button onClick={() => navigate("/signup")} className="signupBtn">
                Sign Up
              </button>
            </div>
          ) : (
            <div className="right-div">
              {/* <IoMdNotificationsOutline size={30} /> */}
              <a
                className="signupBtn me-2"
                href={SWITCH_URL}
                style={{ marginLeft: 0 }}
              >
                Switch to Artist Cube
              </a>

              <div>
                <div
                  className="navbar-profile-div"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={user.photoURL ?? profileImg}
                    className="navbar-profile-img"
                    alt="Profile"
                  />
                  <span className="navbar-profile-text">
                    {user.displayName ?? ""}
                  </span>
                  <span className="navbar-icon">
                    <ExpandMoreRoundedIcon />
                  </span>
                </div>

                <ul className="dropdown-menu">
                  <li>
                    <Link
                      className="dropdown-item nav-dropdown-item"
                      to="/my-events"
                    >
                      My Events
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item nav-dropdown-item"
                      to="/my-services"
                    >
                      My Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item nav-dropdown-item"
                      to="/my-requests"
                    >
                      My Requests
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item nav-dropdown-item"
                      to="/profile-settings"
                    >
                      Profile Settings
                    </Link>
                  </li>
                  <li>
                    <hr className="dropdown-divider" />
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/login"
                      style={{ color: "red" }}
                      onClick={logout}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        {/* {(!userData || currentPath == "/login" || currentPath == "/signup") && (
          <div id="mobile" onClick={() => setClicked(!clicked)}>
            <i
              id="bar"
              className={clicked ? "fas fa-times" : "fas fa-bars"}
            ></i>
          </div>
        )} */}
      </nav>
    </>
  );
}
