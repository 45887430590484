import axios from "axios";
import { toast } from "react-toastify";
import { PROD_URL } from "../helpers/constants";
import { UserData } from "../screens/signup-login/types";

class StripeProvider {
  async createIndividualSupplierCheckout(
    idToken: string,
    eventId: string,
    eventServiceId: string,
    stripeAccountId: string
  ): Promise<any> {
    try {
      const response = await axios.get(
        `${PROD_URL}/create-checkout-individual/event/${eventId}/eventService/${eventServiceId}/${stripeAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      console.log(
        `${PROD_URL}/create-checkout-individual/event/${eventId}/eventService/${eventServiceId}/${stripeAccountId}`
      );
      console.log("stripe checkout", response.data);
      return response.data.session.url;
    } catch (error: any) {
      console.error("Error creating stripe checkout", error);
      toast.error(
        "Error creating stripe checkout: " + error.response.data.message
      );
      return null;
    }
  }

  async stripeConnectWithUser(
    apiToken: string,
    code: string
  ): Promise<UserData | null> {
    try {
      console.log("apitoke", apiToken);
      const response = await axios.get(
        `${PROD_URL}/complete-stripe-request/${code}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      console.log("Response from conecting stripe account", response);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
      return null;
    }
  }

  async checkStripeAccountStatus(
    apiToken: string,
    stripeAccountId: string
  ): Promise<boolean | null> {
    try {
      const response = await axios.get(
        `${PROD_URL}/check-stripe-account-status/${stripeAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      console.log("Response from conecting stripe account status", response);
      if (response.status === 200) {
        return response.data.connected;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      //toast.error(error.message);
      console.log(error);
      return null;
    }
  }

  async deAuthorizeAccount(
    apiToken: string,
    stripeAccountId: string
  ): Promise<UserData | null> {
    try {
      const response = await axios.get(
        `${PROD_URL}/deauthorize-account/${stripeAccountId}`,
        {
          headers: {
            Authorization: `Bearer ${apiToken}`,
          },
        }
      );
      console.log("Deauthorizing account", response);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      toast.error(error.message);
      console.log(error);
      return null;
    }
  }

  async getAuthorizeUrl(): Promise<string | null> {
    try {
      const response = await axios.get(`${PROD_URL}/authorize-url`);
      console.log("Getting authorize url", response);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error: any) {
      //toast.error(error.message);
      console.log(error);
      return null;
    }
  }
}

export default StripeProvider;
