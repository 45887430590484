import React, { useEffect } from "react";
import "./App.css";
import LandingPage from "./screens/landing-page";
import CreateEvent from "./screens/create-event";
import Signup from "./screens/signup-login/signup";
import Login from "./screens/signup-login/login";
import {
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
  useParams,
} from "react-router-dom";
import SupplierInfo from "./screens/supplier-info";
import MyEvents from "./screens/my-events";
import ProfileSettings from "./screens/profile-settings";
import BookEvent from "./screens/book-event";
import MyServices from "./screens/my-services";
import MyRequests from "./screens/my-requests";
import TestChat from "./screens/test-chat";
import { ToastContainer } from "react-toastify";
import { auth } from "./utils/firebase.utils";
import { GeneralProvider } from "./context/GeneralContext";
import { ServicesProvider } from "./context/ServiceContext";
import { EventsProvider } from "./context/EventContext";
import { ChatbotProvider } from "./context/ChatbotContext";
import CookieBanner from "./components/cookie-banner";
import Impressum from "./screens/impressum";
import AuthProvider from "./context/AuthContext";
import { useCookies } from "react-cookie";
import PrivateRoute from "./components/private-route";
import AuthRoute from "./components/auth-route";

const SupplierInfoWrapper = () => {
  const { supplier_id } = useParams();
  return <SupplierInfo key={supplier_id} />;
};

const LandingPageWrapper = () => {
  const { serviceCategory } = useParams();
  return <LandingPage key={serviceCategory} />;
};

function App() {
  const [cookies] = useCookies(["cookieConsent"]);
  const router = createHashRouter([
    {
      path: "/:serviceCategory?",
      element: <LandingPageWrapper />,
    },
    {
      path: "/create-event",
      element: <CreateEvent />,
    },
    {
      path: "/login",
      element: <AuthRoute element={<Login />} />,
    },
    {
      path: "/signup",
      element: <AuthRoute element={<Signup />} />,
    },
    {
      path: "/event/:category/:service_name/:city/:supplier_id",
      element: <SupplierInfoWrapper />,
    },
    {
      path: "/my-events",
      element: <PrivateRoute element={<MyEvents />} />,
    },
    {
      path: "/profile-settings",
      element: <PrivateRoute element={<ProfileSettings />} />,
    },
    {
      path: "/book-event",
      element: <PrivateRoute element={<BookEvent />} />,
    },
    {
      path: "/my-services",
      element: <PrivateRoute element={<MyServices />} />,
    },
    {
      path: "/my-requests",
      element: <PrivateRoute element={<MyRequests />} />,
    },
    {
      path: "/test-chat",
      element: <TestChat />,
    },
    {
      path: "/impressum",
      element: <Impressum />,
    },
  ]);

  return (
    <>
      <AuthProvider>
        <GeneralProvider>
          <ServicesProvider>
            <EventsProvider>
              <ChatbotProvider>
                <RouterProvider router={router} />
              </ChatbotProvider>
            </EventsProvider>
          </ServicesProvider>
        </GeneralProvider>
        {!cookies.cookieConsent && <CookieBanner />}
      </AuthProvider>
    </>
  );
}

export default App;
